<!-- <router-outlet></router-outlet> -->
<form [formGroup]="invStkRprtFrm" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 mb-2">
                <div class="card-body p-4 border-0">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">Facility</label>
                                    <ng-template #cstmStore let-model="item">
                                        <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Facility" id="Store"
                                        formControlName="storeId" [typeahead]="lstFacs" [typeaheadScrollable]="true"
                                        [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                        [adaptivePosition]="true" class="form-control form-control-sm"
                                        (typeaheadOnSelect)="changeFn($event, 'slct')" (change)="changeFn($event, 'chng')" 
                                        [attr.disabled]="(isFacDsbl && (!stkRprtMatView || invStkRprtFrm.get('productId')?.value ||
                                            invStkRprtFrm.get('expireBefore')?.value || lstGrid.length <= 1 ) || isBtch) ? true : null" 
                                        [typeaheadOptionsLimit]="500"/>
                                </div>
                                <div class="form-group col-xl-3" *ngIf="!shwAPrds">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.productName}}</span> <br />
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                        formControlName="productId" [typeahead]="lstAllMats" [(ngModel)]="matName"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="productName" [adaptivePosition]="true"
                                        class="form-control form-control-sm" [typeaheadOptionsLimit]="500"
                                        (typeaheadOnSelect)="chngProduct($event, 'byStr', 'slct')" (change)="chngProduct($event, 'byStr', 'chng')"                                       
                                        [attr.disabled]="((lstGrid.length != 1 && stkRprtMatView) || (invStkRprtFrm.get('expireBefore')?.value) || (invStkRprtFrm.get('batchNo')?.value)) ? true : null">
                                </div>
                                <div class="form-group col-xl-3" *ngIf="shwAPrds">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct1 let-model="item">
                                        <span class="font-weight-bold">{{model.name}}</span> <br />
                                    </ng-template>
                                    <input name="product" type="text" placeholder="Filter by Material" id="Product"
                                        formControlName="productId" [typeahead]="lstMatsAll" [typeaheadOptionsLimit]="500"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct1"
                                        typeaheadOptionField="name" [adaptivePosition]="true" class="form-control form-control-sm"
                                        (typeaheadOnSelect)="chngProduct($event, 'all', 'slct')" (change)="chngProduct($event, 'all', 'chng')"
                                        [attr.disabled]="invStkRprtFrm.get('expireBefore')?.value != '' && stkRprtMatView ? true : null">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Deviant type</label>
                                    <select class="form-select form-select-sm" formControlName="abnormalityType" #AbnrmlType
                                        [(ngModel)]= "devntType" [attr.disabled]="(bachNo) ? true : null">
                                        <option value="">-Select-</option>
                                        <option value="200">Zero Stock...</option>
                                        <option value="201">&lt; Min</option>
                                        <option value="202">&gt; Max</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-3" *ngIf="AbnrmlType.value!=''">
                                    <label for="textBox">Deviant Duration</label>
                                    <input name="duration" type="text" placeholder="Enter Deviant Duration"
                                        id="duration" class="form-control form-control-sm" formControlName="duration"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-row row mb-2">
                            <div class="form-group col-md-3">
                                <label for="textBox">Expires before</label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" placeholder="Filter by Expires before"
                                        bsDatepicker class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                        onpaste="return false" [dateCustomClasses]="cstmDt" formControlName="expireBefore"
                                        [minDate]="dtToDay" onkeypress='return event.charCode == 8' (bsValueChange)="onChngDT($event)"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }" [attr.disabled]="(lstGrid.length != 1 &&
                                            stkRprtMatView) && invStkRprtFrm.get('productId')?.value ? true : null" readonly>
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen" [attr.disabled]="(lstGrid.length != 1 && stkRprtMatView)
                                                && invStkRprtFrm.get('productId')?.value ? true : null">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpED.hide();clearBSDate('expireBefore', 'first')" id="cdEB"
                                            [attr.disabled]="(lstGrid.length != 1 && stkRprtMatView) && invStkRprtFrm.get('productId')?.value ? true : null">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-3" *ngIf="stkRprtMatView">
                                <label for="textBox">Batch enabled</label>
                                <select class="form-select form-select-sm" formControlName="batchEnb">
                                    <option value="">ALL</option>
                                    <option value="wb">Batch enabled</option>
                                    <option value="nb">Batch not enabled</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-3">
                                <label>Batch ID</label>
                                <input placeholder="Enter Batch ID" class="form-control form-control-sm text-uppercase"
                                    type="text" formControlName="batchNo" [attr.disabled]="(btShow || devntType)
                                        ? true : null" (change)="onBatchChng($event)"
                                    onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 &&
                                        event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))' [(ngModel)]="bachNo">
                            </div>
                            <div class="col-xl-4" [hidden]="slctPrdId" style="width: 35% !important;">
                                <span class="pl-xl-3">Material Category:
                                    <span *ngIf="isMatAll">ALL</span>
                                    <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                        <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                            {{mc.name}}
                                        </span>
                                    </span>
                                </span>&nbsp;
                                <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                    role="button" aria-expanded="false" aria-controls="multiCollapseExample2"
                                    id="sr1MC" (click)="isMC=!isMC">
                                    <i class="fa fa-filter"></i>
                                </span>
                                <div class="row">
                                    <div class="col">
                                        <div class="collapse multi-collapse" id="multiCollapseExample2">
                                            <div class="p-0 mb-0 pt-1 pb-2 border-0">
                                                <div class="row">
                                                    <div class="col-xl-6 col-sm-12" style="width: 50% !important;">
                                                        <div class="row">
                                                            <div class="">                                         
                                                                <ng-multiselect-dropdown
                                                                    [settings]="dropdownSettings" [(ngModel)]="slctdMat"
                                                                    [data]="lstMatTags" formControlName="includeProductBadge"
                                                                    (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                    (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                    (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                    (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                    [ngClass]="{ 'is-invalid': isExprt && lstShowMatTags.length==0 }">
                                                                </ng-multiselect-dropdown>
                                                                <div class="invalid-feedback" *ngIf="isExprt && lstShowMatTags.length==0"
                                                                    style="width: 150% !important;">
                                                                    <div *ngIf="slctdMat.length==0">Please choose Material Category</div>
                                                                    <div *ngIf="slctdMat.length!=0">Please save chosen Material Category(s)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-sm-12" style="width: 50% !important;">
                                                        <div class="mt-1">
                                                            <button class="btn btn-sm btn-outline-light me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2"
                                                                (click)="isMC=false;onResetTags('m')">Reset</button>
                                                            <button class="btn btn-sm btn-primary me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2"
                                                                href="#multiCollapseExample2" role="button"
                                                                (click)="isMC=false;onTagsSave('m')">Save</button>
                                                            <button class="btn btn-sm btn-light" (click)="isMC=false;onCncl('m')"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button" id="cnclMBtn"
                                                                href="#multiCollapseExample2">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-2 pt_2" style="width: 15% !important;">
                                <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" id="sr1AS"
                                    aria-expanded="false" aria-controls="box1" (click)="isAS=!isAS;onAS();">Advance Search</button>
                            </div>
                        </div>
                        <div id="box1" class="collapse">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">State</label>
                                    <select formControlName="state" class="form-select form-select-sm"
                                        (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                                        <!-- [attr.disabled]="stkRprtMatView ? true : null" -->
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">District</label>
                                    <select formControlName="district" class="form-select form-select-sm"
                                        (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                        <!-- [attr.disabled]="stkRprtMatView || !shwDst ? true : null" -->
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Taluk/Block</label>
                                    <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                        <!-- [attr.disabled]="stkRprtMatView || !shwDst || !shwBlks ? true : null" -->
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-xl-5" [hidden]="stkRprtMatView">
                                    <span class="pl-xl-3">Facility Category:
                                        <span *ngIf="isFacAll">ALL</span>
                                        <span *ngIf="!isFacAll && lstShowFacTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                                {{fc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample1" 
                                        id="sr1FC" (click)="isFC=!isFC">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">
                                                                    <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                        [data]="lstFacTags" formControlName="includeStoreBadge"                                                                        
                                                                        (onSelect)="onMultiSlct('s', 'f', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                                        [(ngModel)]="slctdFac">
                                                                    </ng-multiselect-dropdown>
                                                                    <div *ngIf="isExprt && lstShowFacTags.length==0" class="invalid-feedback">
                                                                        Please choose Facility Category
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="isFC=false;onResetTags('f')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2" role="button"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="isFC=false;onTagsSave('f')">Save</button>
                                                                <button class="btn btn-sm btn-light" id="cnclFBtn" (click)="isFC=false;onCncl('f')"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <!-- <a href="btn btn-sm btn-link" [routerLink]="['/stk-rprt/help']" target="_blank">Help ?</a> 
                                &nbsp; &nbsp; &nbsp;  -->
                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="isExprt=false;crntPage=1;btShow=false;onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="isExprt=false;crntPage=1;LoadData()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0" *ngIf="!stkRprtMatView && !expireView && !strView">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid.length!=0 && !isExptHdn">
                        <div class="col-xl-4 mb-2">
                            <div class="row">
                                <div class="col-xl-4">
                                    <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                                <div class="col-xl-8">
                                    <select class="form-select form-select-sm" id="ee1">
                                        <option value="nb">Export without batch</option>
                                        <option value="wb">Export with batch</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-4">
                            <div *ngIf="lstGrid.length!=0" class="mt-2">{{pageEntrs}}</div>
                            <!-- <span style="display: inline-block;">
                            <label class="me-2">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                        </div>
                        <div class="col-xl-4">
                            <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist" hidden>
                                <li class="">
                                    <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo"
                                        data-bs-toggle="pill" href="#pills-tabview" role="tab"
                                        aria-controls="pills-tabview" aria-selected="true"><i class="fa fa-table me-0"
                                            aria-hidden="true"></i> List view
                                    </a>
                                </li>
                                <li class="">
                                    <a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                        href="#pills-map1" role="tab" aria-controls="pills-map" aria-selected="false" (click)=loadMaps()>
                                        <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-4">
                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'str')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content" *ngIf="lstGrid.length!=0">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table-multi-columns table-fill mt-2 mb-2 horizontal-scroll scroll-demo p-0 border-0 table_spacing"
                                style="height: auto !important;" *ngIf="lstGrid.length==0">
                                <table class="table table-sm text-center table-small-font table-striped table-bordered align-middle"
                                    cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%">Item No.</th>
                                            <th style="width: 60%" class="text-center">Facility</th>
                                            <th style="width: 20%" title="Temperature" class="toggle-data">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                    <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                    <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                                </svg>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="3" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap" *ngIf="lstGrid.length!=0">
                                <table class="text-center table-small-font main-table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colspan="3" class="freeze tbl-hdr-clr">
                                                <table class="w-300p">
                                                    <tr>
                                                        <th style="width: 20%">Item No.</th>
                                                        <th style="width: 60%" class="text-center">Facility</th>
                                                        <th style="width: 20%" title="Temperature" class="toggle-data">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                                <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                                            </svg>
                                                        </th>
                                                    </tr>
                                                </table>
                                            </th>                                            
                                            <th *ngFor="let hdr of lstGrid[0]?.hdrs" class="tbl-hdr-clr p-2">{{hdr}}</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid.length!=0">
                                        <tr>
                                            <td colspan="3" class="freeze">
                                                <table class="w-300p">
                                                    <tr>
                                                        <td class="text-center"><b>Total</b></td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td *ngFor="let ttl of lstGrid[0]?.total"><b>{{ttl}}</b></td>
                                        </tr>
                                        <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i=index">
                                            <td colspan="3" class="freeze">
                                                <table class="w-300p">
                                                    <tr>
                                                        <td style="width: 20%" class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                                        <td style="width: 60%" (click)="btShow= true;onStoreClk(item.kName, item.kId)" class="text-left">
                                                            <p class="f-14">
                                                                <a [routerLink]="">{{item.kName}}</a><br />
                                                                <sup>{{item.kAdrs}}</sup>
                                                            </p>
                                                        </td>
                                                        <td style="width: 20%">
                                                            <!-- <div id="ec-dn-{{i}}" style="height: 50px; width: 50px;"
                                                                class="toggle-data" data-toggle="modal" data-target="#exampleModal">
                                                            </div> -->
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td [ngStyle]="{ 'backgroundColor': col.stockIndicator != null ? col.stockIndicator.backgroundColor : null,
                                                'color': col.stockIndicator != null ? '#000000' : null }" *ngFor="let col of item.matData"
                                                [title]="'Min: '+col.invMin+', Max: '+col.invMax">{{col.currentStock == null ? 'NA' : col.currentStock}}
                                                <!-- +'\ncity: '+col.city -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid?.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'str')"></pagination-controls>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 text-start">
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }"
                                            class="clrCde p-1 me-2">Normal</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                            class="clrCde p-1 me-2">Zero Stock</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                            class="clrCde p-1 me-2">Min</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                            class="clrCde p-1 me-2">Max</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-map1" role="tabpanel" aria-labelledby="pills-map-tabinfo">
                            <div class="card border-0">
                                <div class="card-body p-2">
                                    <div class="clearfix"></div>
                                    <!-- <div id="locMap"></div> -->
                                    <div id="eMap" style="height: 600px; width:100%"></div>
                                    <!--  <div id="map-container-google-1" class="z-depth-1-half map-container"
                                        style="width: 100%; height: 500px">
                                        <iframe
                                            src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            width="100%" height="100%" frameborder="0" style="border:0"
                                            allowfullscreen></iframe>
                                    </div>-->
                                    <div class="clearfix"></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="modal-header ws">
                    <h5 class="modal-title" id="exampleModalLabel">Achnera CHC</h5>
                    <button type="button" class="close btn btn-sm  btn-light" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="fcModal"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0" *ngIf="stkRprtMatView">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 p-0">
                <div class="card-body border-0 pb-2">
                    <div class="row">
                        <div class="col-xl-4 text-start">
                            <div *ngIf="lstMats.length!=0">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-4">
                            <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                <li class="">
                                    <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo"
                                        data-bs-toggle="pill" href="#pills-tabview" role="tab"
                                        aria-controls="pills-tabview" aria-selected="true"><i class="fa fa-table me-0"
                                            aria-hidden="true"></i>List view</a>
                                </li>
                                <li class="" *ngIf="false" hidden><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                        href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                        <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-4">
                            <div class="float-end" *ngIf="lstMats.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mat')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="row" *ngIf="lstMats.length!=0 && slctdStrId != undefined && slctdStrId != null && slctdStrId != '' && !isExptHdn">
                                <div class="col-xl-4 mb-2">
                                    <div class="row">
                                        <div class="col-xl-4">
                                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE2()">
                                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                            </button>
                                        </div>
                                        <div class="col-xl-8">
                                            <select class="form-select form-select-sm" id="ee2">
                                                <option value="nb">Export without batch</option>
                                                <option value="wb">Export with batch</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered table1">
                                    <thead>
                                        <tr>
                                            <th>Item No.</th>
                                            <th>Material</th>
                                            <th>Total stock</th>
                                            <th>&nbsp;</th>
                                            <th>Allocated</th>
                                            <th>Available</th>
                                            <th>In-transit </th>
                                            <th>Min</th>
                                            <th>Max</th>
                                            <th style="width: 30%;">Last updated</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstMats.length!=0">
                                        <tr *ngFor="let item of lstMats | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i = index"
                                            [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                                'color': item.stockIndicator != null ? '#000000' : null }">
                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.totalStock}}</td>
                                            <td title="Batch Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row" *ngIf="item.batchManagement"> <i class="fa fa-list-alt"></i>
                                                </a>
                                            </td>
                                            <td title="Allocated Stock Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'a')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.allocatedStock}}
                                                </a>
                                            </td>
                                            <td>{{item.availableStock}}</td>
                                            <td title="In-transit Stock Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'i')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.intransitStock}}
                                                </a>
                                            </td>
                                            <td title="Min-Max Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.invMin}}
                                                </a>
                                            </td>
                                            <td title="Min-Max Details">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.invMax}}
                                                </a>
                                            </td>
                                            <td>{{item.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}</td>
                                            <td title="History Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'h')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row"><i class="fa fa-history"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstMats.length==0">
                                        <tr>
                                            <td colspan="11" class="text-center">
                                                {{noRcrdsTxt}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" pb-3 pt-3">
                                <div class="row" *ngIf="lstMats.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mat')"></pagination-controls>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 text-start">
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }"
                                            class="clrCde p-1 me-2">Normal</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                            class="clrCde p-1 me-2">Zero Stock</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                            class="clrCde p-1 me-2">Min</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                            class="clrCde p-1 me-2">Max</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0" *ngIf="strView">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid1.length!=0 && !isExptHdn">
                        <div class="col-xl-2">
                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE3()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                        <div class="col-xl-3">
                            <select class="form-select form-select-sm" id="ee3">
                                <option value="nb">Export without batch</option>
                                <option *ngIf="prdBtchEnbld" value="wb">Export with batch</option>
                            </select>
                        </div>
                        <div class="col-xl-7" *ngIf="isShwMapBtn">
                            <div class="float-end">
                                <button class="btn btn-sm btn-primary" (click)="LoadOSM('m')">
                                    <i class="fa fa-desktop me-0"></i>&nbsp;Map View
                                </button>
                            </div>
                        </div>
                        <div class="col-xl-7" *ngIf="false">
                            <div class="row">
                                <div class="col-xl-6">
                                    <div class="float-start">
                                        <button class="btn btn-sm btn-info" (click)="LoadOSM('m')">
                                            <i class="fa fa-desktop me-0"></i>&nbsp;Map View (PM)
                                        </button>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-end">
                                        <button class="btn btn-sm btn-info" (click)="LoadOSM('w')">
                                            <i class="fa fa-desktop me-0"></i>&nbsp;Map View (PM)
                                        </button>
                                        <!-- <button class="btn btn-sm btn-info" [routerLink]="['/lflt']"
                                            target="_blank" id="atOSM" hidden>
                                            <i class="fa fa-desktop me-0"></i>&nbsp;Map View (PM)
                                        </button> -->
                                        <a class="btn btn-info btn-sm" role="button" [routerLink]="['/lflt']"
                                            target="_blank" id="atOSM" hidden></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-1">
                    </div>
                    <div class="row" *ngIf="lstGrid1.length!=0">
                        <div class="col-xl-4">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-4"></div>
                        <div class="col-lg-4">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mstStr')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered table1">
                                    <thead>
                                        <tr>
                                            <th>Item No.</th>
                                            <th>Facility</th>
                                            <th>Total stock</th>
                                            <th></th>
                                            <th class="text-center"> Allocated</th>
                                            <th class="text-center">Available</th>
                                            <th>In-transit </th>
                                            <th>Min.</th>
                                            <th>Max.</th>
                                            <th>Last updated</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid1?.length!=0">
                                        <tr *ngFor="let item of lstGrid1 | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};let i = index"
                                            [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                                'color': item.stockIndicator != null ? '#000000' : null }">
                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td><a [routerLink]="" [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }">
                                                {{item.storeName}}</a><br />
                                                <sup>{{item.location}}</sup></td>
                                            <td>{{item.totalStock}}</td>
                                            <td>
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    title="Batch Details" class="expand-row toggle-data"
                                                    *ngIf="item.batchEnabled"><i class="fa fa-list-alt"></i>
                                                </a>
                                            </td>
                                            <td>{{item.allocatedStock}}</td>
                                            <td>{{item.availableStock}}</td>
                                            <td>{{item.intransitStock}}</td>
                                            <td title="Min-Max Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.minStock}}
                                                </a>
                                            </td>
                                            <td title="Min-Max Details">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.maxStock}}
                                                </a>
                                            </td>
                                            <td>{{item.updatedOn | date: "dd-MM-yyyy hh:mm aa"}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstGrid1.length==0">
                                        <tr>
                                            <td colspan="10" class="text-center">
                                                {{noRcrdsTxt}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid1.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mstStr')"></pagination-controls>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 text-start">
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }"
                                            class="clrCde p-1 me-2">Normal</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                            class="clrCde p-1 me-2">Zero Stock</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                            class="clrCde p-1 me-2">Min</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                            class="clrCde p-1 me-2">Max</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0" *ngIf="expireView">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid2.length!=0 && !isExptHdn">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE4()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                        <hr class="mt-2">
                    </div>
                    <div class="row" *ngIf="lstGrid2.length!=0">
                        <div class="col-xl-4 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-4">
                        </div>
                        <div class="col-xl-4 text-start">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'expires')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered table1 text-center">
                                    <thead>
                                        <tr>
                                            <th>Item No.</th>
                                            <th>Material</th>
                                            <th>Facility</th>
                                            <th class="text-center">Batch ID</th>
                                            <th class="text-center">Manufactured date</th>
                                            <th class="text-center">Manufacturer</th>
                                            <th>Expiry date </th>
                                            <th>Stock-In Batch</th>
                                            <th>Last updated</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid2?.length!=0">
                                        <tr
                                            *ngFor="let item of lstGrid2 | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};let i = index">
                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td><a [routerLink]="">{{item.product_name}}</a></td>
                                            <td><a [routerLink]="">{{item.store_name}}</a><br />
                                                <sup>{{item.location}}</sup>
                                            </td>
                                            <td>{{item.batch_no}}</td>
                                            <td>{{item.manufactured_date != null ? (item.manufactured_date | date:
                                                "dd-MM-yyyy") : ''}}</td>
                                            <td>{{item.manufacturer_name}}</td>
                                            <td>{{item.expiry_date != null ? (item.expiry_date | date: "dd-MM-yyyy")
                                                :
                                                ''}}</td>
                                            <td>{{item.total_stock}}</td>
                                            <td>{{item.updated_on != null ? (item.updated_on | date: "dd-MM-yyyy
                                                hh:mm
                                                aa") : ''}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstGrid2.length==0">
                                        <tr>
                                            <td colspan="9" class="text-center">
                                                {{noRcrdsTxt}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid2.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'expires')"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- did not use below three span tags data, its for refernce -->
<span [hidden]="true">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 mb-2">
                <div class="card-body p-2 border-0">
                    <form [formGroup]="invStkRprtMatFrm" autocomplete="off">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Facility</label>
                                        <input type="text" placeholder="Filter by Facility" class="form-control"
                                            id="storeIdT" [value]="facName" [attr.disabled]="false">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Material</label>
                                        <ng-template #cstmProduct let-model="item">
                                            <span class="font-weight-bold">{{model.productName}}</span> <br />
                                            <span class="fs-12">{{model.location}}</span>
                                        </ng-template>
                                        <input name="store" type="text" placeholder="Filter by Material"
                                            formControlName="productIdT" [typeahead]="lstAllMats1" [typeaheadOptionsLimit]="500"
                                            (typeaheadOnSelect)="onChngMtrl($event)" id="Product"
                                            [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                            typeaheadOptionField="productName" [adaptivePosition]="true"
                                            class="form-control" [attr.disabled]="true">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Deviant type</label>
                                        <select id="inputState" class="form-select form-select-sm" formControlName="dvntType">
                                            <option value="">-Select-</option>
                                            <option value="200">Zero Stock...</option>
                                            <option value="201">&lt; Min</option>
                                            <option value="202">&gt; Max</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Expires before</label>
                                        <div class="input-group">
                                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                class="form-control optinbg bsDP-bg" #dpED="bsDatepicker" readonly
                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                onkeypress='return event.charCode == 8' formControlName="expireBefore"
                                                placeholder="Expires before" [minDate]="dtToDay">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-bsdp p-2" type="button"
                                                    (click)="dpED.toggle()" [attr.aria-expanded]="dpED.isOpen">
                                                    <i class="fa fa-calendar"></i>
                                                </button>
                                                <button class="btn btn-outline-bsdp p-2" type="button"
                                                    title="Clear Date"
                                                    (click)="dpED.hide();clearBSDate('expireBefore', 'second')">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Batch enabled</label>
                                        <select id="inputState" class="form-select form-select-sm" formControlName="batchEnb">
                                            <option value="">ALL</option>
                                            <option value="wb">Batch enabled</option>
                                            <option value="nb">Batch not enabled</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-row row mb-2 mt-2" hidden>
                                <div class="form-group col-md-3">
                                    <label for="textBox">State</label>
                                    <input type="text" placeholder="Filter by State" class="form-control" id="State"
                                        formControlName="state">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">District/County</label>
                                    <input type="text" placeholder="Filter by District/County" class="form-control"
                                        id="District-County" formControlName="district">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Taluk/Block</label>
                                    <input type="text" placeholder="Filter by Taluk/Block" class="form-control"
                                        id="District-County" formControlName="block">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Likely to stock out (days)</label>
                                    <input type="text" placeholder="Filter by Likely to stock out (days)"
                                        class="form-control" id="District-County" formControlName="stockOut">
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-xl-5">
                                    <span class="pl-xl-3">Material Category: <span
                                            class="badge badge-pink mb-0">GMSD</span></span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExamplef1"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExamplef1">
                                        <i class="fa fa-filter"></i></span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExamplef1">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">
                                                                    <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstMatTags" formControlName="includeCat"
                                                                        (onSelect)="onItemSelect('inM', $event)"
                                                                        (onSelectAll)="onSelectAll('inM', $event)">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="">
                                                                <div class="">
                                                                    <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstMatTags" formControlName="includeCat"
                                                                        (onSelect)="onItemSelect('inM', $event)"
                                                                        (onSelectAll)="onSelectAll('inM', $event)">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    type="submit">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2"
                                                                    type="submit">Save</button>
                                                                <button class="btn btn-sm btn-light"
                                                                    data-bs-toggle="collapse"
                                                                    href="#multiCollapseExamplef1" role="button"
                                                                    aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-light me-2 mb-2"
                                        (click)="crntPage=1;invStkRprtMatFrm.reset();lstMats=lstAllMats">Reset</button>
                                    <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;onFilter()">Filter</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2 p-2">
                    <div class="row">
                        <div class="col-xl-4 text-start">
                            <div *ngIf="lstMats.length!=0">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-4">
                            <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                <li class="">
                                    <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo"
                                        data-bs-toggle="pill" href="#pills-tabview" role="tab"
                                        aria-controls="pills-tabview" aria-selected="true"><i class="fa fa-table me-0"
                                            aria-hidden="true"></i>
                                        List view</a>
                                </li>
                                <li class="" *ngIf="false" hidden><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                        href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                        <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-4 text-start">
                            <div class="float-end" *ngIf="lstMats.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mat')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered table1">
                                    <thead>
                                        <tr>
                                            <th>Item No.</th>
                                            <th>Material</th>
                                            <th>Total stock</th>
                                            <th>&nbsp;</th>
                                            <th>Allocated</th>
                                            <th>Available</th>
                                            <th>In-transit </th>
                                            <th>Min</th>
                                            <th>Max</th>
                                            <th style="width: 30%;">Last updated</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstMats1.length!=0">
                                        <tr *ngFor="let item of lstMats1 | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i = index"
                                            [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                            'color': item.stockIndicator != null ? '#000000' : null }">
                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td>{{item.productName}}</td>
                                            <td>{{item.totalStock}}</td>
                                            <td title="Batch Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row" *ngIf="item.batchManagement"> <i class="fa fa-list-alt"></i>
                                                </a>
                                            </td>
                                            <td title="Allocated Stock Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'a')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.allocatedStock}}
                                                </a>
                                            </td>
                                            <td>{{item.availableStock}}</td>
                                            <td title="In-transit Stock Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'i')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.intransitStock}}
                                                </a>
                                            </td>
                                            <td title="Min-Max Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.invMin}}
                                                </a>
                                            </td>
                                            <td title="Min-Max Details">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.invMax}}
                                                </a>
                                            </td>
                                            <td>{{item.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}</td>
                                            <td title="History Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'h')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row"><i class="fa fa-history"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstMats1.length==0">
                                        <tr>
                                            <td colspan="11" class="text-center">
                                                {{noRcrdsTxt}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" pb-3 pt-3">
                                <div class="row" *ngIf="lstMats1.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mat')"></pagination-controls>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 text-start">
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }" class="clrCde p-1 me-2">Normal</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }" class="clrCde p-1 me-2">Zero Stock</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }" class="clrCde p-1 me-2">Min</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }" class="clrCde p-1 me-2">Max</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-map2" role="tabpanel" aria-labelledby="pills-map-tabinfo">
                            <div class="card border-0">
                                <div class="card-body p-2">
                                    <div class="clearfix"></div>
                                    <div id="map-container-google-1" class="z-depth-1-half map-container" style="width: 100%; height: 500px">
                                        <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</span>
<span [hidden]="true">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 mb-2">
                <div class="card-body p-2 border-0">
                    <form>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Facility</label>
                                        <input name="store" type="text" class="form-control"
                                            placeholder="Filter by Facilities" [attr.disabled]="true" />
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Material</label>
                                        <input name="store" type="text" class="form-control"
                                            placeholder="Filter by Material" id="material">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">AbnormalityType</label>
                                        <select class="form-select form-select-sm">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-12 pt_2 text-end">
                                    <button class="btn btn-primary" data-toggle="collapse" href="#box1"
                                        aria-expanded="false" aria-controls="box1">Advance Search</button>
                                </div>
                            </div>
                            <div id="box1" class="collapse">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Expires before</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" autocomplete="off" placeholder="Filter by Expires before">
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Batch</label>
                                        <input name="batch" type="text" class="form-control" placeholder="Filter by Batch" id="batch" />
                                    </div>
                                </div>
                                <div class="form-row row mb-2">
                                    <div class="form-group col-md-3">
                                        <label for="textBox">State</label>
                                        <select class="form-select form-select-sm">
                                            <option value="null">-Select-</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="textBox">District/County</label>
                                        <select class="form-select form-select-sm">
                                            <option value="null">-Select-</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Taluk/Block</label>
                                        <select class="form-select form-select-sm">
                                            <option value="null">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-2 mt-3">
                                    <div class="col-xl-5">
                                        <span class="pl-xl-3">Facility tags :
                                            <span class="badge badge-pink mb-0">GMSD</span>
                                        </span>&nbsp;
                                        <span class="badge badge-pink mb-0">SVS</span>&nbsp;
                                        <span class="badge badge-pink mb-0">RVS</span>
                                        <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExamplef1"
                                            role="button" aria-expanded="false"
                                            aria-controls="multiCollapseExamplef1"><i class="fa fa-filter"></i></span>
                                        <div class="row">
                                            <div class="col">
                                                <div class="collapse multi-collapse" id="multiCollapseExamplef1">
                                                    <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-sm-12">
                                                                <div class="row">
                                                                    <label class="col-form-label">Include Category(s)</label>
                                                                    <div>
                                                                        <select class="form-select form-select-sm">
                                                                            <option value="">All</option>
                                                                        </select>
                                                                        <!-- <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstFacTags"
                                                                        formControlName="includeStoreBadge"
                                                                        (onSelect)="onItemSelect('inf', $event)"
                                                                        (onSelectAll)="onSelectAll('inf', $event)">
                                                                    </ng-multiselect-dropdown> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6 col-sm-12">
                                                                <div class="">
                                                                    <label class="col-form-label">Exclude Category(s)</label>
                                                                    <div>
                                                                        <select class="form-select form-select-sm">
                                                                            <option value="">All</option>
                                                                        </select>
                                                                        <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                            [data]="lstFacTags" formControlName="excludeStoreBadge"
                                                                            (onSelect)="onItemSelect('exf', $event)"
                                                                            (onSelectAll)="onSelectAll('exf', $event)">
                                                                            </ng-multiselect-dropdown> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                                <div class="float-end">
                                                                    <button class="btn btn-sm btn-outline-light me-2"
                                                                        type="button">Reset</button>
                                                                    <button class="btn btn-sm btn-primary me-2"
                                                                        type="submit">Save</button>
                                                                    <button class="btn btn-sm btn-light" role="button"
                                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                                        aria-controls="multiCollapseExample1"
                                                                        href="#multiCollapseExamplef1">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid1.length!=0 && !isExptHdn">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE2()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                        <hr class="mt-1">
                    </div>
                    <div class="row" *ngIf="lstGrid1.length!=0">
                        <div class="col-xl-4">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-4">
                            <!-- <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                <li class="">
                                    <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo" data-bs-toggle="pill"
                                        href="#pills-tabview" role="tab" aria-controls="pills-tabview"
                                        aria-selected="true"><i class="fa fa-table me-0" aria-hidden="true"></i>
                                        List view</a>
                                </li>
                                <li class=""><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                        href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                        <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view</a>
                                </li>
                            </ul> -->
                        </div>
                        <div class="col-xl-6 text-start">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mstStr')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered table1">
                                    <thead>
                                        <tr>
                                            <th>Item No.</th>
                                            <th>Facility</th>
                                            <th>Total stock</th>
                                            <th></th>
                                            <th class="text-center"> Allocated</th>
                                            <th class="text-center">Available</th>
                                            <th>In-transit </th>
                                            <th>Min.</th>
                                            <th>Max.</th>
                                            <th>Last updated</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid1?.length!=0">
                                        <tr *ngFor="let item of lstGrid1 | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};let i = index"
                                            [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                            'color': item.stockIndicator != null ? '#000000' : null }">
                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td>{{item.storeName}}</td>
                                            <td>{{item.totalStock}}</td>
                                            <td>
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    title="Batch Details" class="expand-row toggle-data"
                                                    *ngIf="item.batchEnabled"><i class="fa fa-list-alt"></i>
                                                </a>
                                            </td>
                                            <td>{{item.allocatedStock}}</td>
                                            <td>{{item.availableStock}}</td>
                                            <td>{{item.intransitStock}}</td>
                                            <td title="Min-Max Details" class="toggle-data">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.minStock}}
                                                </a>
                                            </td>
                                            <td title="Min-Max Details">
                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                    class="expand-row">{{item.maxStock}}
                                                </a>
                                            </td>
                                            <td>{{item.updatedOn | date: "dd-MM-yyyy hh:mm aa"}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstGrid1.length==0">
                                        <tr>
                                            <td colspan="10" class="text-center">
                                                {{noRcrdsTxt}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid1.length!=0">
                                    <div class="col-xl-6">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6 text-start">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'mstStr')"></pagination-controls>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 text-start">
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }" class="clrCde p-1 me-2">Normal</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }" class="clrCde p-1 me-2">Zero Stock</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }" class="clrCde p-1 me-2">Min</span>
                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }" class="clrCde p-1 me-2">Max</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-end mt-2 col-xl-12">
                            <span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</span>
<span [hidden]="true">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 mb-2">
                <div class="card-body p-2 border-0">
                    <form>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Facility</label>
                                        <input name="store" type="text" class="form-control" placeholder="Filter by Facilities" disabled />
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Material</label>
                                        <input name="store" type="text" class="form-control" placeholder="Filter by Material" id="material">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">AbnormalityType</label>
                                        <select class="form-select form-select-sm">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-12 pt_2 text-end">
                                    <button class="btn btn-primary" data-toggle="collapse" href="#box1"
                                        aria-expanded="false" aria-controls="box1">Advance Search</button>
                                </div>
                            </div>
                            <div id="box1" class="collapse">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Expires before</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" autocomplete="off"
                                                placeholder="Filter by Expires before">
                                            <!-- <div class="input-group-append">
                                                <button class="btn btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                    [attr.aria-expanded]="dpED.isOpen">
                                                    <i class="fa fa-calendar"></i>
                                                </button>
                                                <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                    (click)="dpED.hide();clearBSDate('expireBefore')">
                                                    <i class="fa fa-times"></i>
                                                </button>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row row mb-2">
                                    <div class="form-group col-md-3">
                                        <label for="textBox">State</label>
                                        <select class="form-select form-select-sm">
                                            <option value="null">-Select-</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="textBox">District/County</label>
                                        <select class="form-select form-select-sm">
                                            <option value="null">-Select-</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="textBox">Taluk/Block</label>
                                        <select class="form-select form-select-sm">
                                            <option value="null">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-2 mt-3">
                                    <div class="col-xl-5">
                                        <span class="pl-xl-3">Facility tags :
                                            <span class="badge badge-pink mb-0">GMSD</span>
                                        </span>&nbsp;
                                        <span class="badge badge-pink mb-0">SVS</span>&nbsp;
                                        <span class="badge badge-pink mb-0">RVS</span>
                                        <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExamplef1"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExamplef1">
                                            <i class="fa fa-filter"></i>
                                        </span>
                                        <div class="row">
                                            <div class="col">
                                                <div class="collapse multi-collapse" id="multiCollapseExamplef1">
                                                    <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-sm-12">
                                                                <div class="row">
                                                                    <label class="col-form-label">Include Category(s)</label>
                                                                    <div>
                                                                        <select class="form-select form-select-sm">
                                                                            <option value="">All</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6 col-sm-12">
                                                                <div class="">
                                                                    <label class="col-form-label">Exclude Category(s)</label>
                                                                    <div>
                                                                        <select class="form-select form-select-sm">
                                                                            <option value="">All</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                                <div class="float-end">
                                                                    <button class="btn btn-sm btn-outline-light me-2"
                                                                        type="button">Reset</button>
                                                                    <button class="btn btn-sm btn-primary me-2"
                                                                        type="submit">Save</button>
                                                                    <button class="btn btn-sm btn-light" role="button"
                                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                                        aria-controls="multiCollapseExample1"
                                                                        href="#multiCollapseExamplef1">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <span class="pl-xl-3">Material Category: All</span>&nbsp;
                                        <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                                            <i class="fa fa-filter"></i>
                                        </span>
                                        <div class="row">
                                            <div class="col">
                                                <div class="collapse multi-collapse" id="multiCollapseExample2">
                                                    <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                        <div class="row">
                                                            <div class="col-xl-6 col-sm-12">
                                                                <div class="row">
                                                                    <label class="col-form-label">Include Category(s)</label>
                                                                    <div class="">
                                                                        <select class="form-select form-select-sm">
                                                                            <option value="">All</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-xl-6 col-sm-12">
                                                                <div class="">
                                                                    <label class="col-form-label">Exclude Category(s)</label>
                                                                    <div class="">
                                                                        <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstMatTags"
                                                                        (onSelect)="onItemSelect('exp', $event)"
                                                                        (onSelectAll)="onSelectAll('exp', $event)">
                                                                    </ng-multiselect-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                                <div class="float-end">
                                                                    <button class="btn btn-sm btn-outline-light me-2"
                                                                        type="button">Reset</button>
                                                                    <button class="btn btn-sm btn-primary me-2"
                                                                        type="submit">Save</button>
                                                                    <button class="btn btn-sm btn-light" role="button"
                                                                        data-bs-toggle="collapse" aria-expanded="false"
                                                                        aria-controls="multiCollapseExample1"
                                                                        href="#multiCollapseExample2">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-4">
                            <!-- <button class="btn btn-primary">Export</button> -->
                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE2()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                        <div class="col-xl-8">
                            <select class="form-select form-select-sm" id="ee2">
                                <!-- <option value="nb">Export without batch</option>
                                <option value="wb">Export with batch</option> -->
                            </select>
                        </div>
                        <hr class="mt-2">
                        <!-- <div class="col-xl-2 mb-2 text-end" *ngIf="lstGrid?.length!=0">
                            {{pageEntrs}}
                        </div>
                        <div class="col-lg-2 col-xl-2" *ngIf="lstGrid?.length!=0">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'expires')"></pagination-controls>
                            </div>
                        </div> -->
                    </div>
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-4 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-4">
                            <!-- <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                <li class="">
                                    <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo" data-bs-toggle="pill"
                                        href="#pills-tabview" role="tab" aria-controls="pills-tabview"
                                        aria-selected="true"><i class="fa fa-table me-0" aria-hidden="true"></i>
                                        List view</a>
                                </li>
                                <li class=""><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                        href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                        <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view</a>
                                </li>
                            </ul> -->
                        </div>
                        <div class="col-xl-4 text-start">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'expires')"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered table1">
                                    <thead>
                                        <tr>
                                            <th>Item No.</th>
                                            <th>Material</th>
                                            <th>Facility</th>
                                            <th class="text-center">Batch ID</th>
                                            <th class="text-center">Manufactured date</th>
                                            <th class="text-center">Manufacturer</th>
                                            <th>Expiry date </th>
                                            <th>Total stock</th>
                                            <th>Last updated</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid?.length!=0">
                                        <tr
                                            *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};let i = index">
                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td>{{item.product_name}}</td>
                                            <td>{{item.store_name}}</td>
                                            <td>{{item.batch_no}}</td>
                                            <td>{{item.manufactured_date != null ? (item.manufactured_date | date: "dd-MM-yyyy") : ''}}</td>
                                            <td>{{item.manufacturer_name}}</td>
                                            <td>{{item.expiry_date != null ? (item.expiry_date | date: "dd-MM-yyyy") : ''}}</td>
                                            <td>{{item.total_stock}}</td>
                                            <td>{{item.updated_on != null ? (item.updated_on | date: "dd-MM-yyyy hh:mm aa") : ''}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstGrid.length==0">
                                        <tr>
                                            <td colspan="9" class="text-center">
                                                {{noRcrdsTxt}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class=" pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'expires')"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</span>