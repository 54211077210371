<form [formGroup]="cnfgGnrlFrm" autocomplete="off">
    <div class="card p-3 pb-0 pt-2 border-0">
        <div class="card-header p-3 pb-1 pt-2">
            <div class="h6 float-start">General configuration</div>
            <!-- <div class="small float-end">Last updated on 7/5/19 10:28 AM by <a [routerLink]="">Devdutt Mishra</a></div> -->
        </div>
        <div class="card-body p-0 mt-2 mb-0"> <!-- formGroupName="configJson" -->
            <div class="row mt-2">
                <div class="col-sm-8" formGroupName="localeDefaults">
                    <div class="card border shadow-none  p-0 h-100 mt-0 mb-0">
                        <div class="card-header p-2 fw-bold">
                            Locale defaults
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Specify defaults for locale of various Facilities in this domain
                                (any changes to properties below will be reflected as defaults only for Facilities
                                created after the change)</div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row mb-2 mt-3">
                                        <div class="form-group col-xl-4 mb-3">
                                            <label for="country_id">Country <span class="text-danger">*</span></label>
                                            <select id="country_id" class="form-select form-select-sm" formControlName="country_id"
                                                (change)="onLocChng($event, 'country')" [attr.disabled]="true">
                                                <option value="null">-Select-</option>
                                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label for="state_id">State</label>
                                            <select id="state" class="form-select form-select-sm" formControlName="state"
                                            (change)="onLocChng($event, 'state')">
                                            <option value="null">-Select-</option>
                                            <option *ngFor="let s of stsLst" [value]="s.stateid">{{s.statename}}</option>
                                        </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label for="district_id">District</label>
                                            <select id="disrict_id" class="form-select form-select-sm" formControlName="disrict_id">
                                            <!-- (change)="onLocChng($event, 'district')" -->
                                            <option value="null">-Select-</option>
                                            <option *ngFor="let s of dstrtLst" [value]="s.districtid">{{s.districtname}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label for="textBox">Language</label>
                                            <select id="language_id" class="form-select form-select-sm" formControlName="language_id">
                                                <option value="null">Select Language</option>
                                                <option *ngFor="let ln of LngLst" value="{{ln.id}}">{{ln.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label for="textBox">Timezone <span class="text-danger">*</span></label>
                                            <select id="time_zone_id" class="form-select form-select-sm"
                                                formControlName="time_zone_id">
                                                <option value="null">Select Timezone</option>
                                                <option *ngFor="let tz of tmZoneLst" value="{{tz.id}}">{{tz.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label for="textBox">Currency</label>
                                            <select class="form-select form-select-sm">
                                                <option>Indian Rupees (INR)</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-12 mb-2">
                                            <label for="textBox">Custom page header</label>
                                            <textarea class="form-control h-160" id="exampleFormControlTextarea1" rows="1"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card shadow-none border p-0 h-100 mt-0 mb-0">
                        <div class="card-header p-2 fw-bold">
                            Domain photos
                        </div>
                        <div class="card-body carousel_box p-2">
                            <div class="row mt-3 mb-3">
                                <div class="col-xl-12">
                                    <div class="text-center" *ngIf="lstPics.length==0">
                                        <!-- <i class="f-24 fa fa-upload" aria-hidden="true"></i>&nbsp; &nbsp;<br /> -->
                                        <p>No Image Available</p>
                                    </div>
                                    <div id="carouselExampleIndicators" class="carousel slide carousel_box"
                                        data-bs-ride="carousel" *ngIf="lstPics.length!=0">
                                        <div class="carousel-inner">
                                            <div [ngClass]="i==0?'carousel-item active':'carousel-item'"
                                                *ngFor="let dp of lstPics; let i=index">
                                                <img src="{{dp}}" class="d-block img-fluid w-100" style="height: 250px" alt="...">
                                            </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button"
                                            data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button"
                                            data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="row">
                                <div class="col-sm-12 mt-3">
                                    <label class="form-label" for="fileDomn"></label>
                                    <input type="file" class="form-control form-control-sm btn btn-sm btn-primary" id="fileDomn" #FileDmn
                                        (change)="onSlctFile($event)" accept="image/*" /> <!-- image/png, image/gif, image/gpg, image/jpeg -->
                                </div>
                                <div class="col-sm-12 mt-3">
                                    <!-- <button type="button" class="btn btn-sm btn btn-primary me-2 mb-2">Delete</button> -->
                                    <button type="button" class="btn btn-sm btn-primary me-2 mb-2" *ngIf="gnrlPrmsn" (click)="onUpload()">Save</button>
                                    <button type="button" class="btn btn-sm btn-light mb-2" *ngIf="gnrlPrmsn" (click)="FileDmn.value=''" id="cncl">Cancel</button>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-0">
                <div class="col-sm-12">
                    <div class="card border shadow-none p-0 mt-3 mb-3">
                        <div class="card-header p-2 fw-bold">Support settings</div>
                        <div class="card-body p-2">
                            <div class="small">
                                Support contact information can be configured per role. There can be a different support
                                person or number for operators, managers or administrators. Support information can be
                                either specified by selecting a user (in the User column), or by explicitly entering a name,
                                phone number or email. Selecting an existing user will automatically default the support
                                contact information to the user's name, phone number and email.
                            </div>
                            <div class="mt-2 small">
                                <strong>NOTE</strong>:
                                Phone number is mandatory. If you do not select an existing user, then phone number has
                                to be entered. Name and email are optional.
                            </div>
                            <div class="table-responsive mt-3" formArrayName="support_contacts">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" hidden>Id</th>
                                            <th scope="col">Role</th>
                                            <th scope="col" class="text-center">User</th>
                                            <th scope="col" class="text-center">Name</th>
                                            <th scope="col" class="text-center">Phone number</th>
                                            <th scope="col" class="text-center">Email Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of GetSprtCntcts().controls;let tabIndx=index;" [formGroupName]="tabIndx">
                                            <td hidden>{{item?.get('role_id')?.value}}</td>
                                            <td>{{item?.get('roleName')?.value}}</td>
                                            <td>
                                                <ng-template #cstmUser let-model="item">
                                                    <span class="font-weight-bold">{{model.userId}}</span>
                                                </ng-template>
                                                <input name="user" type="text" placeholder="Type to get suggestions.."
                                                    formControlName="userName" [typeahead]="lstUsers" class="form-control form-control-sm"
                                                    [typeaheadItemTemplate]="cstmUser" typeaheadOptionField="userId"
                                                    [adaptivePosition]="true" [typeaheadScrollable]="true"
                                                    (change)="onSlctUser($event, 'sc', tabIndx, 'c')" [typeaheadOptionsLimit]="500"
                                                    (typeaheadOnSelect)="onSlctUser($event, 'sc', tabIndx, 's')" />
                                            </td>
                                            <td>
                                                <input type="text" placeholder="Enter name" value="Admin"
                                                    class="form-control form-control-sm" id="" formControlName="name">
                                            </td>
                                            <td>
                                                <input type="text" placeholder="ex: +91 9111" value="ex: +91 9111"
                                                    class="form-control form-control-sm" id="" formControlName="phone_number">
                                            </td>
                                            <td>
                                                <input type="text" placeholder="asd@ssa.com" value="asd@ssa.com"
                                                    class="form-control form-control-sm" id="" formControlName="email_id">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card border shadow-none p-0 mt-2">
                        <div class="card-header  p-2 fw-bold">Administrative contacts</div>
                        <div class="card-body p-2">
                            <div class="small">
                                Administrative contacts are typically administrators or supervisors in the domain and will
                                appear wherever others have to contact them (e.g. in the monitoring application). Two types
                                of administrative contacts can be configured - primary and secondary. This will provide
                                options for users to contact either the primary or secondary contacts. Administrative contact
                                information can be specified by selecting a user (in the User column). Selecting a user will
                                automatically fill the Administrative contact details such as name, phone number, and email.
                            </div>
                            <div class="table-responsive mt-4" formArrayName="admin_contacts">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Category</th>
                                            <th scope="col" class="text-center">User</th>
                                            <th scope="col" class="text-center">Name</th>
                                            <th scope="col" class="text-center">Phone number</th>
                                            <th scope="col" class="text-center">Email Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of GetAdmnCntcts().controls;let admIndx=index;"
                                            [formGroupName]="admIndx">
                                            <td>{{item?.get('admin_type')?.value}}</td>
                                            <td>
                                                <ng-template #cstmUser let-model="item">
                                                    <span class="font-weight-bold">{{model.userId}}</span>
                                                </ng-template>
                                                <input name="user" type="text" placeholder="Type to get suggestions.."
                                                    formControlName="userName" [typeahead]="lstUsers" class="form-control form-control-sm"
                                                    [typeaheadItemTemplate]="cstmUser" typeaheadOptionField="userId"
                                                    [adaptivePosition]="true" [typeaheadScrollable]="true"
                                                    (change)="onSlctUser($event, 'ac', admIndx, 'c')" [typeaheadOptionsLimit]="500"
                                                    (typeaheadOnSelect)="onSlctUser($event, 'ac', admIndx, 's')" />
                                            </td>
                                            <td>{{item?.get('name')?.value}}</td>
                                            <td>{{item?.get('phone_number')?.value}}</td>
                                            <td>{{item?.get('email_id')?.value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="text-end pb-3">
                        <button type="button" class="btn btn-sm btn-primary" *ngIf="gnrlPrmsn" (click)="onSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>