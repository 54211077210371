<div class="card p-0 border-0">
    <div class="card-body p-2">
        <form [formGroup]="usrprfForm" autocomplete="off">
            <div class="row">
                <div class="col-xl-12 col-xs-12 mb-2">
                    <div class="card p-0 border-0">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mt-3">
                                    <div class="card p-0 mb-3 mt-3">
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                            <h6>Identification Details</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>User Id:</label></div>
                                                <div class="col-xl-9">{{data?.username}}</div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Role:</label></div>
                                                <div *ngIf="!editRole" class="col-xl-3">{{data?.role}}</div>
                                                <div *ngIf="editRole" class="form-group col-xl-3 mb-2">
                                                    <select id="role_id" class="form-select form-select-sm" formControlName="role_id">
                                                        <option value="">-Select-</option>
                                                        <option *ngFor="let rl of rolesLst" [value]="rl.id">{{rl.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6"  *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editRole=true"><i *ngIf="!editRole" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                    <a (click)="editRole=false; clearCntrlValue('role_id')"><i *ngIf="editRole" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Tags:</label></div>
                                                <div *ngIf="!editTags" class="col-xl-3">{{data?.badges}}</div>
                                                <div class="form-group col-xl-3 " *ngIf="editTags">
                                                    <select class="form-select form-select-sm"
                                                        formControlName="user_badge" (change)="onItemSelect($event)">
                                                        <option value="">-Select-</option>
                                                        <option *ngFor="let usrBdg of userBadges" [value]="usrBdg.id">
                                                            {{usrBdg.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6"  *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editTags=true"><i *ngIf="!editTags" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                    <a (click)="editTags=false; clearCntrlValue('user_badge')"><i *ngIf="editTags" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Permissions:</label></div>
                                                <div *ngIf="!editpermsn" class="col-xl-3">{{data?.permissions}}</div>
                                                <div class="form-group col-xl-3 " *ngIf="editpermsn">
                                                    <select id="permissions" class="form-select form-select-sm"
                                                        formControlName="permissions">
                                                        <option value="default">Default</option>
                                                        <option value="view">View only</option>
                                                        <option value="asset view">Asset view only</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6"  *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editpermsn=true"><i *ngIf="!editpermsn"
                                                            class="fa fa-pencil" aria-hidden="true"></i></a>
                                                    <a (click)="editpermsn=false; clearCntrlValue('permissions')"><i *ngIf="editpermsn"
                                                         class="fa fa-remove" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2 mt-3">
                                            <h6>Personal Details</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>First name:</label></div>
                                                <div class="col-xl-3" *ngIf="!editFName">{{data?.first_name}}</div>
                                                <div class="form-group col-xl-3" *ngIf="editFName">
                                                    <input type="text" placeholder=""
                                                        class="form-control form-control-sm" id="first_name" formControlName="first_name">
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editFName=true"><i *ngIf="!editFName" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                    <a (click)="editFName=false; clearCntrlValue('first_name')"><i *ngIf="editFName" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Last name:</label></div>
                                                <div class="col-xl-3" *ngIf="!editLName">{{data?.last_name}}</div>
                                                <div class="form-group col-xl-3" *ngIf="editLName">
                                                    <input type="text" placeholder=""
                                                        class="form-control form-control-sm" id="last_name" formControlName="last_name">
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editLName=true"><i *ngIf="!editLName" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                        <a (click)="editLName=false; clearCntrlValue('last_name')"><i *ngIf="editLName" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Gender:</label></div>
                                                <div class="col-xl-3" *ngIf="!editGender"> {{data?.gender =="M" ?
                                                    "Male": data?.gender == "F" ? "Female" : data?.gender == null ? "NA"
                                                    : "Others"}}</div>
                                                <div class="form-group col-xl-3 " *ngIf="editGender">
                                                    <select id="gender" class="form-select form-select-sm"
                                                        formControlName="gender" >
                                                        <option value="">Select Gender</option>
                                                        <option value="M">Male</option>
                                                        <option value="F">Female</option>
                                                        <option value="O">Other</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editGender=true"><i *ngIf="!editGender"
                                                            class="fa fa-pencil" aria-hidden="true"></i></a>
                                                    <a (click)="editGender=false; clearCntrlValue('gender')"><i *ngIf="editGender"
                                                         class="fa fa-remove" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Year of birth:</label></div>
                                                <div class="col-xl-3" *ngIf="!editDob">{{data?.dob}}</div>
                                                <div class="form-group col-xl-3 " *ngIf="editDob">
                                                    <div class="input-group">
                                                        <input type="text" autocomplete="off" placeholder="Year of birth" bsDatepicker
                                                            class="form-control form-control-sm optinbg bsDP-bg" #dpDOB="bsDatepicker" onpaste="return false"
                                                            [maxDate]="maxdate"
                                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY', minMode: 'year',
                                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                            onkeypress='return event.charCode == 8' formControlName="dob" readonly>
                                                        <div class="input-group-append">
                                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpDOB.toggle()"
                                                                [attr.aria-expanded]="dpDOB.isOpen">
                                                                <i class="fa fa-calendar"></i>
                                                            </button>
                                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                (click)="dpDOB.hide();clearBSDate('dob')">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editDob=true"><i *ngIf="!editDob" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                        <a (click)="editDob=false;clearCntrlValue('dob')"><i *ngIf="editDob" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2 mt-3">
                                            <h6>Contact Details</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Mobile:</label></div>
                                                <div class="col-xl-3" *ngIf="!editMobile">{{data?.mobile }}</div>
                                                <div class="form-group col-xl-3 " *ngIf="editMobile">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text bg-light text-dark"
                                                                id="basic-addon1">+91</span>
                                                        </div>
                                                        <input type="text" placeholder="Mobile number"
                                                            class="form-control form-control-sm" id="mobile"
                                                            formControlName="mobile"
                                                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.mobile.errors }"
                                                            minlength="10" maxlength="13">
                                                        <div *ngIf="isSbmtd && fc.mobile.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="fc.mobile.errors.minlength || fc.mobile.errors.maxlength">
                                                                Mobile number should be min. of 10 and max. of 13 digits
                                                                only
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editMobile=true"><i *ngIf="!editMobile"
                                                            class="fa fa-pencil" aria-hidden="true"></i></a>
                                                        <a (click)="editMobile=false;clearCntrlValue('mobile')"><i *ngIf="editMobile" 
                                                        class="fa fa-remove" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Alt Mobile:</label></div>
                                                <div class="col-xl-3" *ngIf="!editAltMobile">{{data?.alt_number}}</div>

                                                <div class="form-group col-xl-3" *ngIf="editAltMobile">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text bg-light text-dark"
                                                                id="basic-addon">+91</span>
                                                        </div>
                                                        <input type="text" placeholder="Alternate mobile number"
                                                            class="form-control form-control-sm" id="alt_number"
                                                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                            formControlName="alt_number"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.alt_number.errors }"
                                                            minlength="10" maxlength="13">
                                                        <div *ngIf="isSbmtd && fc.alt_number.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="fc.alt_number.errors.minlength || fc.alt_number.errors.maxlength">
                                                                Alternate mobile number should be min. of 10 and max. of
                                                                13 digits only
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editAltMobile=true"><i *ngIf="!editAltMobile"
                                                            class="fa fa-pencil" aria-hidden="true"></i></a>
                                                        <a (click)="editAltMobile=false; clearCntrlValue('alt_number')"><i *ngIf="editAltMobile"
                                                         class="fa fa-remove" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Email:</label></div>
                                                <div class="col-xl-3" *ngIf="!editMail">{{data?.email}}</div>
                                                <div class="form-group col-xl-3 " *ngIf="editMail">
                                                    <div class="input-group">
                                                        <input type="text" placeholder="Email"
                                                            class="form-control form-control-sm" id="email"
                                                            formControlName="email"
                                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.email.errors }">
                                                        <div *ngIf="isSbmtd && fc.email.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fc.email.errors.email">Email should be in format
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6">
                                                    <div *ngIf="!isPrflHdn">
                                                        <a *ngIf="prflEdt" (click)="editMail=true"><i *ngIf="!editMail" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                        <a (click)="editMail=false;clearCntrlValue('email') "><i *ngIf="editMail"
                                                         class="fa fa-remove" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2 mt-3">
                                            <h6>Location Details</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Country:</label></div>
                                                <div class="col-xl-3" *ngIf="!editCountry">{{data?.country}}</div>
                                                <div *ngIf="editCountry" class="form-group col-xl-3">
                                                    <select id="country_id" class="form-select form-select-sm" formControlName="country_id"
                                                     (change)="stsLst=[]" [attr.disabled]="true">
                                                        <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6"  *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editCountry=true"><i *ngIf="!editCountry"
                                                            class="fa fa-pencil" aria-hidden="true"></i></a>
                                                    <a (click)="editCountry=false;clearCntrlValue('country_id')"><i *ngIf="editCountry"
                                                            class="fa fa-remove" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>State:</label></div>
                                                <div class="col-xl-3" *ngIf="!editState">{{data?.state}}</div>
                                                <div class="form-group col-xl-3" *ngIf="editState">
                                                    <select id="state_id" class="form-select form-select-sm"
                                                        formControlName="state_id" (change)="onNestedSlct($event, 'state_id')">
                                                        <option value="">-Select-</option>
                                                        <option *ngFor="let s of stsLst" [value]="s.id">
                                                            {{s.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6"  *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editState=true"><i *ngIf="!editState"
                                                            class="fa fa-pencil" aria-hidden="true"></i></a>
                                                    <a (click)="editState=false; clearCntrlValue('state_id')"><i *ngIf="editState"
                                                         class="fa fa-remove" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Language:</label></div>
                                                <div class="col-xl-3" *ngIf="!editLang">{{data?.language}}</div>

                                                <div class="form-group col-xl-3 " *ngIf="editLang">
                                                    <select id="language_id" class="form-select form-select-sm"
                                                        formControlName="language_id">
                                                        <option value="">Select Language</option>
                                                        <option *ngFor="let ln of LngLst" [value]="ln.id">{{ln.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6"  *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editLang=true"><i *ngIf="!editLang" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                    <a (click)="editLang=false; clearCntrlValue('language_id')"><i *ngIf="editLang" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-xl-3"><label>Time Zone:</label></div>
                                                <div class="col-xl-3" *ngIf="!editTmzn">{{data?.time_zone}}</div>

                                                <div class="form-group col-xl-3 " *ngIf="editTmzn">
                                                    <select id="time_zone_id" class="form-select form-select-sm" formControlName= "time_zone_id">
                                                        <option value="">Select Timezone</option>
                                                        <option *ngFor="let tz of tmZoneLst" [value]="tz.id">{{tz.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-xl-6" *ngIf="false && !isPrflHdn">
                                                    <a *ngIf="prflEdt" (click)="editTmzn=true"><i *ngIf="!editTmzn" class="fa fa-pencil"
                                                            aria-hidden="true"></i></a>
                                                    <a (click)="editTmzn=false; clearCntrlValue('time_zone_id')"><i *ngIf="editTmzn" class="fa fa-remove"
                                                            aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                    <div class="float-end" *ngIf="prflEdt && !isPrflHdn">
                                        <button class="btn btn-sm btn-primary me-2" type="submit"
                                            (click)="isSbmtd=true;updateUser()">Save</button>
                                        <button class="btn btn-sm btn-light" data-bs-toggle="collapse"
                                            href="#multiCollapseExamplef1" role="button" aria-expanded="false"
                                            aria-controls="multiCollapseExample1" (click)="isSbmtd=false;onCancel()" >Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-header border-0 p-1">
                                <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                                    <li class="nav-item"><a class="nav-link active" id="Facilty-tab-tab"
                                            data-bs-toggle="tab" href="#Facilty-tab" role="tab"
                                            aria-controls="Facilty-tab" aria-selected="true">Facility</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="row mt-3">
        <div class="col-xl-2"></div>
        <div class="col-xl-8">
            <div class="card h-100 mb-0">
                <div class="card-body p-3">
                    <div class="table-responsive table_spacing">
                        <table class="table table-striped table-sm table-bordered border-primary f-14">
                            <thead>
                                <tr class="text-center">
                                    <th class="text-center">Item.No</th>
                                    <th class="col-lg-8 text-center">Facility Name</th>
                                    <th class="col-lg-4 text-center">Location</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="lstGrd?.length!=0">
                                <tr class="text-center" *ngFor="let d of lstGrd; let i = index">
                                    <td class="text-center">{{i+1}}</td>
                                    <td class="text-center">{{d.store_name}}</td>
                                    <td class="text-center">{{d.location}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstGrd?.length==0">
                                <tr>
                                    <td colspan="3" class="text-center">{{noRcrdTxt}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>