<form [formGroup]="cnfgDshbFrm" autocomplete="off">
    <div class="card p-0">
        <div class="card-header shadow-none p-2 pb-1 pt-2">
            <div class="h6 float-start">Dashboard Configuration</div>
            <!-- <div class="small float-end">Last updated on 31/10/20 4:56 PM by <a [routerLink]="">Devdutt Mishra</a></div> -->
        </div>
        <div class="card-body p-2" formGroupName="configJson">
            <div class="row mt-2">
                <div class="col-sm-12">
                    <div class="card shadow-sm p-0 mt-0 mb-0" formGroupName="general">
                        <div class="card-header p-2">
                            <h6 class="mb-0">General</h6>
                        </div>
                        <div class="card-body p-2 mt-0 mb-0">
                            <div class="row">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="row mb-0">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">Default material category filter</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstMatTags"
                                                placeholder="Categories" formControlName="default_material_tag_filter"
                                                (onSelect)="onChng('matTagEx', $event, 'os')" [(ngModel)]="slctdDMT"
                                                (onSelectAll)="onChng('matTagEx', $event, 'osa')"
                                                (onDeSelect)="onChng('matTagEx', $event, 'ds')"
                                                (onDeSelectAll)="onChng('matTagEx', $event, 'dsa')">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">Exclude facility categories</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
                                                placeholder="Tags" formControlName="exclude_store_tags"
                                                (onSelect)="onChng('facBadge', $event, 'os')" [(ngModel)]="slctdEST"
                                                (onSelectAll)="onChng('facBadge', $event, 'osa')"
                                                (onDeSelect)="onChng('facBadge', $event, 'ds')"
                                                (onDeSelectAll)="onChng('facBadge', $event, 'dsa')">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="form-group col-xl-12 mt-3" *ngIf="false">
                                            <label for="textBox" class="f-w-500 mb-2 me-2">Dashboard
                                                visibility</label><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox"
                                                    id="dashboard_visibility" formControlName="dashboard_visibility">
                                                <label class="form-check-label" for="dashboard_visibility">
                                                    Allow Managers to view dashboards
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="temperature" *ngIf="false">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Temperature</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="form-group col-sm-4 mt-1">
                                <label class="textBox mb-2" for="flexCheckDefault">
                                    Temperature status to exclude by default
                                </label>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstTempStatus"
                                    placeholder="None" formControlName="temperature_states_to_exclude_by_default"
                                    (onSelect)="onChng('tmpStates', $event, 'os')" [(ngModel)]="slctdTSED"
                                    (onSelectAll)="onChng('tmpStates', $event, 'osa')"
                                    (onDeSelect)="onChng('tmpStates', $event, 'ds')"
                                    (onDeSelectAll)="onChng('tmpStates', $event, 'dsa')">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="overview_dashboard">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Overview dashboard</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="form-group col-sm-4 mt-1">
                                <label class="textBox mb-2" for="flexCheckDefault">
                                    Default period for activity
                                </label>
                                <select class="form-select form-select-sm" formControlName="default_period_for_activity">
                                    <option value="">-Select-</option>
                                    <option value="7">7 days</option>
                                    <option value="15">15 days</option>
                                    <option value="30">30 days</option>
                                    <option value="45">45 days</option>
                                    <option value="60">60 days</option>
                                    <option value="90">90 days</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="activity_dashboard">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Activity dashboard</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="form-group row ms-2 ">
                                <div class="form-check col-sm-3 mt-1" *ngIf="false">
                                    <div class="row">
                                        <div class="col-sm-8 ps-0">
                                            <label class="form-check-label" for="show_activity_panel">
                                                Show Activity panel
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><input class="form-check-input" type="checkbox"
                                                id="show_activity_panel" formControlName="show_activity_panel"></div>
                                    </div>
                                </div>
                                <div class="form-check col-sm-3 mt-1" *ngIf="false">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <label class="form-check-label" for="show_revenue_panel">
                                                Show Revenue panel
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><input class="form-check-input" type="checkbox"
                                                id="show_revenue_panel" formControlName="show_revenue_panel"></div>
                                    </div>
                                </div>
                                <div class="form-check col-sm-3 mt-1" *ngIf="false">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <label class="form-check-label" for="show_orders_panel">
                                                Show Indents panel
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><input class="form-check-input" type="checkbox"
                                                id="show_orders_panel" formControlName="show_orders_panel"></div>
                                    </div>
                                </div>
                                <div class="form-check col-sm-3 mt-1" *ngIf="false">
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <label class="form-check-label" for="show_inventory_panel">
                                                Show Stock Management panel
                                            </label>
                                        </div>
                                        <div class="col-sm-2"><input class="form-check-input" type="checkbox"
                                                id="show_inventory_panel" formControlName="show_inventory_panel"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-2 mt-0 mb-0">
                            <div class="row">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="row mb-0">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">Default material category filter</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstMatTags"
                                                placeholder="Categories" formControlName="default_act_material_tag_filter"
                                                (onSelect)="onChng('matActTagEx', $event, 'os')" [(ngModel)]="slctdActDMT"
                                                (onSelectAll)="onChng('matActTagEx', $event, 'osa')"
                                                (onDeSelect)="onChng('matActTagEx', $event, 'ds')"
                                                (onDeSelectAll)="onChng('matActTagEx', $event, 'dsa')">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">Default facility categories</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
                                                placeholder="Tags" formControlName="default_act_facility_tag_filter"
                                                (onSelect)="onChng('facActBadge', $event, 'os')" [(ngModel)]="slctdActEST"
                                                (onSelectAll)="onChng('facActBadge', $event, 'osa')"
                                                (onDeSelect)="onChng('facActBadge', $event, 'ds')"
                                                (onDeSelectAll)="onChng('facActBadge', $event, 'dsa')">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="form-group col-xl-12 mt-3" *ngIf="false">
                                            <label for="textBox" class="f-w-500 mb-2 me-2">Dashboard
                                                visibility</label><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox"
                                                    id="dashboard_visibility" formControlName="dashboard_visibility">
                                                <label class="form-check-label" for="dashboard_visibility">
                                                    Allow Managers to view dashboards
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="date_of_actual_transaction">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Date of actual transaction</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="row mt-3">
                                <div class="col-sm-3 ">
                                    <label class="form-check-label f-w-500" for="disable_date_of_actual_transaction">
                                        Disable date of actual transaction
                                    </label>
                                </div>
                                <div class="col-sm-1"><input class="form-check-input" type="checkbox"
                                        id="disable_date_of_actual_transaction"
                                        formControlName="disable_date_of_actual_transaction"></div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xl-3 mb-2">
                                    <label for="default_transaction_type_filter">Default transaction type filter</label>
                                    <select id="default_transaction_type_filter" class="form-select form-select-sm"
                                        formControlName="default_transaction_type_filter">
                                        <option value="">All Transactions</option>
                                        <option [value]="txnTY.id" *ngFor="let txnTY of lstTxnTyps">{{txnTY.name}}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div class="form-group col-xl-3">
                                    <label for="default_store_tag_filter">Default facility category filter</label>
                                    <select class="form-select form-select-sm" id="default_store_tag_filter"
                                        formControlName="default_store_tag_filter">
                                        <option value="">Select category</option>
                                        <option [value]="facTag.id" *ngFor="let facTag of lstFacTags">{{facTag.name}}
                                        </option>
                                    </select>
                                </div> -->
                                <div class="form-group col-xl-4">
                                    <label for="textBox">Default facility categories</label>
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
                                        placeholder="Tags" formControlName="default_store_tag_filter"
                                        (onSelect)="onChng('facltyBadge', $event, 'os')" [(ngModel)]="slctdFcltyLST"
                                        (onSelectAll)="onChng('facltyBadge', $event, 'osa')"
                                        (onDeSelect)="onChng('facltyBadge', $event, 'ds')"
                                        (onDeSelectAll)="onChng('facltyBadge', $event, 'dsa')">
                                    </ng-multiselect-dropdown>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="activity_views" *ngIf="false">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Activity views</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="form-group col-xl-6 mb-2">
                                    <label for="textBox">Hide activity views for users with categories</label>
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userBadges"
                                        placeholder="Hide activity views for users with categories"
                                        formControlName="hide_activity_views_for_users_with_tags"
                                        (onSelect)="onChng('userBadge', $event, 'os')" [(ngModel)]="slctdAVUT"
                                        (onSelectAll)="onChng('userBadge', $event, 'osa')"
                                        (onDeSelect)="onChng('userBadge', $event, 'ds')"
                                        (onDeSelectAll)="onChng('userBadge', $event, 'dsa')">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="abnormal_stock_views">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Stock Deviant</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="form-group col-xl-6 mb-2  select-tag-border">
                                    <label for="default_material_tag_filter">Default material tag filter</label>
                                    <select class="form-select form-select-sm" id="default_material_tag_filter"
                                        formControlName="default_material_tag_filter">
                                        <option value="">Select tag</option>
                                        <option *ngFor="let matTag of lstMatTags" [value]="matTag.id">{{matTag.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2" formGroupName="asset_dashboard" *ngIf="false">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Asset dashboard</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="form-group col-xl-4 mb-2">
                                    <label for="textBox">Default monitoring type</label>
                                    <select id="default_monitoring_type" class="form-select form-select-sm" (change)="chngMntrgType($event)"
                                        formControlName="default_monitoring_type" [(ngModel)]="default_monitoring_type">
                                        <option value="">-Select-</option>
                                        <option value="1">Monitoring assets</option>
                                        <option value="2">Monitored assets</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-4 pb-5" *ngIf="mntredType">
                                    <label for="textBox">Default asset types</label>
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst"
                                        placeholder="All" formControlName="default_asset_types"
                                        (onSelect)="onChng('assetType', $event, 'os')" [(ngModel)]="slctdDAT"
                                        (onSelectAll)="onChng('assetType', $event, 'osa')"
                                        (onDeSelect)="onChng('assetType', $event, 'ds')"
                                        (onDeSelectAll)="onChng('assetType', $event, 'dsa')">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end pb-5 ">
                <button type="button" class="btn btn-sm btn-primary" (click)="onSave()" *ngIf="cnfgDbPrmsn">Save</button>
            </div>
        </div>
    </div>
</form>