<div class="row p-2 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <form [formGroup]="invtrasLstFrm" autocomplete="off">
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-row row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Facility</label>
                            <ng-template #cstmStore let-model="item">
                                <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="store" type="text" placeholder="Filter by Facility" id="Store"
                                formControlName="storeId" [typeahead]="lstStrs" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                (typeaheadOnSelect)="changeFn($event, 'onSlct')" (change)="changeFn($event, 'chng')" 
                                [attr.disabled]="lstStrs.length == 1 ? true : null" [typeaheadOptionsLimit]="500" />
                        </div>
                        <div class="form-group col-xl-3" *ngIf="!shwAPrds"> <!-- [hidden]="invtrasLstFrm.get('storeId')?.value == null" -->
                            <label for="textBox">Material</label>
                            <ng-template #cstmProduct let-model="item">
                                <span class="font-weight-bold">{{model.productName}}</span> <br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                formControlName="productId" [typeahead]="lstAllMats" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmProduct" typeaheadOptionField="productName" [typeaheadOptionsLimit]="500"
                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                (typeaheadOnSelect)="changeFn2($event, 'byStr', 'onSlct')" (change)="changeFn2($event, 'byStr', 'chng')" />
                        </div>
                        <div class="form-group col-xl-3" *ngIf="shwAPrds"> <!-- [hidden]="invtrasLstFrm.get('storeId')?.value != null" -->
                            <label for="textBox">Material</label>
                            <ng-template #cstmProduct1 let-model="item">
                                <span class="font-weight-bold">{{model.name}}</span> <br />
                            </ng-template>
                            <input name="product" type="text" placeholder="Filter by Material" id="Product"
                                formControlName="productId" [typeahead]="lstAllMats1" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmProduct1" typeaheadOptionField="name"
                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                (typeaheadOnSelect)="changeFn2($event, 'all', 'onSlct')" (change)="changeFn2($event, 'all', 'chng')"
                                [typeaheadOptionsLimit]="500">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Transaction Type</label>
                            <select id="inputState" class="form-select form-select-sm" formControlName="txnType" (change)="onTrnTyp($event)">
                                <option value=''>All</option>
                                <option [value]="txnTY.id" *ngFor="let txnTY of lstTxnTyps">{{txnTY.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3">
                            <label>Batch ID</label>
                            <input placeholder="Enter Batch ID" class="form-control form-control-sm text-uppercase"
                                type="text" formControlName="batchNo" onkeypress='return ((event.charCode >= 65 &&
                                    event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) ||
                                    (event.charCode >= 48 && event.charCode <= 57))'>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-row row mb-2">
                    <p class="mt-2 mb-1"><b>Date of actual transaction : </b></p>
                    <div class="form-group col-md-3">
                        <label for="textBox">From</label>
                        <div class="input-group">
                            <!-- [dateCustomClasses]="cstmDt"  -->
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                (bsValueChange)="onChngDT($event)"
                                onkeypress='return event.charCode == 8' placeholder="From" readonly
                                formControlName="txnsFromDate" [(ngModel)]="frmDate" [maxDate]="todt">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                    [attr.aria-expanded]="dpFD.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpFD.hide();clearBSDate('txnsFromDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="textBox">To</label>
                        <div class="input-group">
                            <!-- [dateCustomClasses]="cstmDt"  -->
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker"
                                readonly [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                (bsValueChange)="onChngDtT($event)" onkeypress='return event.charCode == 8'
                                formControlName="txnsToDate" placeholder="To" [(ngModel)]="todt" [minDate]="frmDate" [maxDate]="dt2day">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                    [attr.aria-expanded]="dpTD.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpTD.hide();clearBSDate('txnsToDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3 pt-4">
                        <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                            aria-controls="box1" (click)="onAS()">Advance Search</button>
                    </div>
                </div>
                <div class="" *ngIf="false">
                    <input type="checkbox" [checked]="isInitTxnDate" (change)="isInitTxnDate=!isInitTxnDate">
                    <label class="ms-2" for="scales">Filter by date of actual transaction</label>
                </div>
                <div id="box1" class="collapse">
                    <div class="form-row row mb-2">
                        <div class="form-group col-md-3">
                            <label for="textBox">State</label>
                            <select formControlName="state" class="form-select form-select-sm"
                                (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                                <option value="null">-Select-</option>
                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">District</label>
                            <select formControlName="district" class="form-select form-select-sm"
                                (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                <option value="null">-Select-</option>
                                <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">Taluk/Block</label>
                            <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                <option value="null">-Select-</option>
                                <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-2 mt-3">
                        <div class="col-xl-6">
                            <span class="pl-xl-3">Facility Category:
                                <span *ngIf="isFacAll">ALL</span>
                                <span *ngIf="!isFacAll && lstShowFacTags.length!=0">
                                    <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                        {{fc.name}}
                                    </span>
                                </span>
                            </span>&nbsp;
                            <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button"
                                aria-expanded="false" aria-controls="multiCollapseExample1">
                                <i class="fa fa-filter"></i>
                            </span>
                            <div class="row">
                                <div class="col">
                                    <div class="collapse multi-collapse" id="multiCollapseExample1">
                                        <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                            <div class="row">
                                                <div class="col-xl-6 col-sm-12">
                                                    <div class="row">
                                                        <div class="">
                                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                [data]="lstFCF" formControlName="storeBadge"
                                                                (onSelect)="onMultiSlct('s', 'f', $event)"
                                                                (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                                (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                                [(ngModel)]="slctdFac">
                                                            </ng-multiselect-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-sm-12">
                                                    <div class="float-end">
                                                        <button class="btn btn-sm btn-outline-light me-2"
                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                            aria-controls="multiCollapseExample1" role="button"
                                                            href="#multiCollapseExample1" (click)="onResetTags('f')">Reset</button>
                                                        <button class="btn btn-sm btn-primary me-2" role="button"
                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                            aria-controls="multiCollapseExample1"
                                                            href="#multiCollapseExample1" (click)="onTagsSave('f')">Save</button>
                                                        <button class="btn btn-sm btn-light" (click)="onCncl('f')"
                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                            aria-controls="multiCollapseExample1" role="button"
                                                            href="#multiCollapseExample1">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6" [hidden]="slctPrdId">
                            <span class="pl-xl-3">Material Category:
                                <span *ngIf="isMatAll">ALL</span>
                                <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                    <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                        {{mc.name}}
                                    </span>
                                </span>
                            </span>&nbsp;
                            <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button"
                                aria-expanded="false" aria-controls="multiCollapseExample2">
                                <i class="fa fa-filter"></i>
                            </span>
                            <div class="row">
                                <div class="col">
                                    <div class="collapse multi-collapse" id="multiCollapseExample2">
                                        <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                            <div class="row">
                                                <div class="col-xl-6 col-sm-12">
                                                    <div class="row">
                                                        <div class="">
                                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                [data]="lstMCF" formControlName="productBadge"
                                                                (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                [(ngModel)]="slctdMat">
                                                            </ng-multiselect-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-sm-12">
                                                    <div class="float-end">
                                                        <button class="btn btn-sm btn-outline-light me-2"
                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                            aria-controls="multiCollapseExample2" role="button"
                                                            href="#multiCollapseExample2" (click)="onResetTags('m')">Reset</button>
                                                        <button class="btn btn-sm btn-primary me-2" role="button"
                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                            aria-controls="multiCollapseExample2"
                                                            href="#multiCollapseExample2" (click)="onTagsSave('m')">Save</button>
                                                        <button class="btn btn-sm btn-light" (click)="onCncl('m')"
                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                            aria-controls="multiCollapseExample2" role="button"
                                                            href="#multiCollapseExample2">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2 mt-3">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Transaction reason:</label>
                            <select class="form-select form-select-sm" formControlName="txnReason">
                                <option value=''>All</option>
                                <option *ngFor="let rs of txnRsns" [value]="rs.reasonId">{{rs.reasonName}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="isInitTxnDate=false;crntPage=1;onResetTags('f');onResetTags('m');onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary mb-2" (click)="isShowDAT=false;crntPage=1;LoadStoreTransactions()">Filter</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<hr />
<div class="row p-2 mt-3">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="row" *ngIf="lstTrans.length!=0">
            <div class="col-xl-4 mb-2">
                <div class="row">
                    <div class="col-xl-4">
                        <button class="btn btn-sm btn-success" (click)="onEE()">
                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                        </button>
                    </div>
                    <div class="col-xl-8">
                        <select class="form-select form-select-sm" id="ee">
                            <option value="nb">Export without batch</option>
                            <option value="wb">Export with batch</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="lstTrans.length!=0">
            <div class="col-xl-6 text-start">
                {{pageEntrs}}
                <!-- <span style="display: inline-block;" class="me-2">
                    <label tyle="display: inline-block;">Filter</label>
                </span>
                <span style="display: inline-block;">
                    <select class="form-select form-select-sm">
                        <option>5</option>
                        <option>10</option>
                    </select>
                </span> -->
            </div>
            <div class="col-xl-6">
                <div class="float-end">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table_spacing table-responsive table-row-spacing">
            <table class="table table-striped table-sm table-bordered horizontal-scroll scroll-demo" id="table_detail">
                <thead>
                    <tr>
                        <th class="text-center">Item No.</th>
                        <th>Facility</th>
                        <th>Material</th>
                        <th class="text-center">Opening stock</th>
                        <th>Operation</th>
                        <th>Quantity</th>
                        <th *ngIf="isShwBE">&nbsp;</th>
                        <th>Closing stock</th>
                        <th>Date of actual transaction</th>
                        <th>Updated on</th>
                        <th>Updated by</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngFor="let item of lstTrans | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};
                        let i=index" [hidden]="lstTrans.length==0">
                    <tr>
                        <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                        <td>
                            <div><a>{{item.storeDto.storeName}}</a></div>
                            <span>{{item.storeLocation}}</span>
                        </td>
                        <td>
                            <div><a>{{item.storeDto.productName}}</a></div>
                            <span *ngIf="item.materialStatus != null && item.materialStatus != ''">
                                <span class="font-wt">Status:</span>&nbsp;{{item.materialStatus}}
                            </span>
                        </td>
                        <td>{{item.openingStock}}</td>
                        <td>
                            <div>{{item.transactionTypeOperation}}</div>
                            <span *ngIf="item.transactionTypeOperation == 'Issues/Net Utilization' || 
                                item.transactionTypeOperation == 'Transfers' || item.transactionTypeOperation == 'Stock-In'">
                                <span *ngIf="item?.storeDto?.rcvngStoreName != null" class="font-wt">
                                    {{item.transactionTypeOperation == 'Issues/Net Utilization' ? 'To:' : 
                                    item.transactionTypeOperation == 'Transfers' ? 'To:' : 
                                    item.transactionTypeOperation == 'Stock-In' ? 'From:' : '' }}</span>&nbsp;
                                <span>{{item?.storeDto?.rcvngStoreName}}</span><br />
                            </span>
                            <span *ngIf="item?.transactionReason != '' && item?.transactionReason != null">
                                <span class="font-wt">Reason:</span>&nbsp;<span>{{item?.transactionReason}}</span>
                            </span>
                        </td>
                        <td>{{item.quantity}}</td>
                        <td *ngIf="isShwBE">
                            <a (click)="item.isB=!item.isB;LoadBatches(i, item.storeDto.storeId, item.storeDto.productId, item.txnId)"
                                class="expand-row" *ngIf="item.productDto.isBatchEnabled"><i class="fa fa-list-alt"></i>
                            </a>
                        </td>
                        <td>{{item.closingStock}}</td>
                        <td >{{item.intialTxnDate != null ? (item.intialTxnDate | date: "dd/MM/yyyy") : ''}}</td>
                        <td>{{item.timeStamp | date: "dd/MM/yyyy hh:mm aa"}}</td>
                        <td><a>{{item.firstName}}</a></td>
                        <td class="text-center">
                            <span class="f-14">
                                <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.sourceType==1"></i>
                                <i class="fa fa-desktop" aria-hidden="true" *ngIf="item.sourceType==2"></i>
                            </span>
                        </td>
                    </tr>
                    <tr class="sub-table no-bg" *ngIf="item.isB">
                        <td align="center" colspan="11">
                            <div class="card m-3 p-0 border-0 rounded-0">
                                <div class="card-header p-2">
                                    <div class="float-start">
                                        <span class="font-weight-bold pt-3"> Batches of {{item.storeDto.productName}}</span>
                                    </div>
                                    <div class="float-end">
                                        <button class="btn btn-sm rounded bg-light p-0" data-toggle="tooltip"
                                            data-placement="top" title="close">
                                            <a class="expand-row" *ngIf="item.isB" (click)="item.isB=!item.isB">
                                                <i class="fa fa-times-circle"></i>
                                            </a>
                                        </button>
                                    </div>
                                </div>
                                <div class="table_spacing m-3 mb-0 table-responsive">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead class="bg-dark text-white">
                                            <tr>
                                                <th>Item No.</th>
                                                <th>Batch ID </th>
                                                <th>Manufactured Date (dd/MM/yyyy)</th>
                                                <th>Manufacturer</th>
                                                <th>Expiry Date (dd/MM/yyyy)</th>
                                                <th>Opening stock</th>
                                                <th>Closing stock</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="item?.lstBats?.length!=0">
                                            <tr *ngFor="let bat of item.lstBats;let j=index">
                                                <td>{{j+1}}</td>
                                                <td>{{bat.batchNo}}</td> <!-- <td>{{bat.batch_no}}</td> -->
                                                <td>{{bat.manufacturedDate | date: "dd/MM/yyyy"}}</td> <!-- <td>{{bat.manufactured_date | date: "dd/MM/yyyy"}}</td> -->
                                                <td>{{bat.manufacturer}}</td> <!-- <td>{{bat.batch_producer_name}}</td> -->
                                                <td>{{bat.expiryDate | date: "dd/MM/yyyy"}}</td> <!-- <td>{{bat.expiry_date | date: "dd/MM/yyyy"}}</td> -->
                                                <td>{{bat.openingBatchStock}}</td> <!-- <td>{{bat.totalStock}}</td> -->
                                                <td>{{bat.closingBatchStock}}</td> <!-- <td>{{bat.available_stock}}</td> -->
                                            </tr>
                                        </tbody>
                                        <tbody [hidden]="item.lstBats?.length!=0">
                                            <tr>
                                                <td colspan="7" class="text-center">{{noRcrdsTxt}}</td>
                                            </tr>
                                        </tbody><br />
                                    </table>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="lstTrans.length==0">
                    <tr>
                        <td colspan="12" class="text-center">{{noRcrdsTxt}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstTrans.length!=0">
            <div class="col-xl-6 text-start">
                {{pageEntrs}}
            </div>
            <div class="col-xl-6">
                <div class="float-end">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>