<div class="card p-0 pb-0 pt-2">
    <div class="card-header shadow-none p-2 pb-1 pt-2">
        <div class="h6 float-start">Tags configuration</div>
        <!-- <div class="small float-end">Last updated on 31/10/20 4:56 PM by <a [routerLink]="">Devdutt Mishra</a></div> -->
    </div>
    <div class="card-body p-2">
        <div class="row mt-2">
            <div class="col-sm-12">
                <div class="card shadow-sm p-0 mt-0 mb-0">
                    <div class="mt-3">
                        <div class="">
                            <div class="nav  nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <a class="nav-link f-w-500 active" id="top-tab1-tab" data-bs-toggle="tab"
                                    href="#top-tab1" role="tab" aria-controls="top-tab1"
                                    aria-selected="true">Product Tags</a>
                                <a class="nav-link f-w-500" id="tab2-top-tab" data-bs-toggle="tab"
                                    href="#top-tab2" role="tab" aria-controls="top-tab2"
                                    aria-selected="false">Facility Tags</a>
                                <a class="nav-link f-w-500" id="tab3-top-tab" data-bs-toggle="tab"
                                    href="#top-tab3" role="tab" aria-controls="top-tab3"
                                    aria-selected="false">Indent Tags</a>
                                <a class="nav-link f-w-500" id="tab5-top-tab" data-bs-toggle="tab"
                                    href="#top-tab4" role="tab" aria-controls="top-tab4"
                                    aria-selected="false">User Tags</a>
                                <a class="nav-link f-w-500" id="tab4-top-tab" data-bs-toggle="tab"
                                    href="#top-tab5" role="tab" aria-controls="top-tab5"
                                    aria-selected="false" *ngIf="false">Route Tags</a>
                                <a class="nav-link f-w-500" id="tab6-top-tab" data-bs-toggle="tab"
                                    href="#top-tab6" role="tab" aria-controls="top-tab6"
                                    aria-selected="false" *ngIf="false">Manufacturer Tags</a>
                                <a class="nav-link f-w-500" id="tab7-top-tab" data-bs-toggle="tab"
                                    href="#top-tab7" role="tab" aria-controls="top-tab7"
                                    aria-selected="false" *ngIf="false">Facility Tags Rank</a>
                            </div>
                        </div>
                        <div class="">
                            <div class="tab-content" id="top-tabContent">
                                <div class="tab-pane fade show active" id="top-tab1" role="tabpanel"
                                    aria-labelledby="top-tab1-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Please specify tags that can be applied to the Products in the system
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center">Product Tag</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let pt of lstTabsData?.lstPT; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{pt.name}}</td>
                                                                    <td>
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true"
                                                                            (click)="onAddDel('productBadges', pt, 'd')" *ngIf="cnfgTagsPrmsn">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstPT?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <input type="text" class="form-control form-control-sm"
                                                                            [(ngModel)]="ptTxt" placeholder="Create custom tag" (keyup) = "enbleAddBtnFn($event)"
                                                                        >
                                                                    </td>
                                                                    <td>
                                                                           <button type="button" class="btn btn-sm btn-primary" (click)="onAddDel('productBadges', ptTxt, 'a');ptTxt='';">Add</button>
                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="top-tab2" role="tabpanel"
                                    aria-labelledby="tab2-top-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Please specify tags that can be applied to the Facilities in the system
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center">Facility Tag</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let st of lstTabsData?.lstST; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{st.name}}</td>
                                                                    <td>
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true" *ngIf="cnfgTagsPrmsn"
                                                                            (click)="onAddDel('storeBadges', st, 'd')">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstST?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <input type="text" class="form-control form-control-sm"
                                                                            [(ngModel)]="stTxt" placeholder="Create custom tag"
                                                                            >
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn btn-sm btn-primary" (click)="onAddDel('storeBadges', stTxt, 'a');stTxt='';">Add</button>
                                                                 </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="top-tab3" role="tabpanel"
                                    aria-labelledby="tab3-top-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Please specify tags that can be applied to the Indents in the system
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center">Indent Tag</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let ot of lstTabsData?.lstOT; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{ot.name}}</td>
                                                                    <td>
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true" *ngIf="cnfgTagsPrmsn"
                                                                            (click)="onAddDel('bookingBadges', ot, 'd')">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstOT?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <input type="text" class="form-control form-control-sm"
                                                                            [(ngModel)]="otTxt" placeholder="Create custom tag"
                                                                            >
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn btn-sm btn-primary" (click)="onAddDel('bookingBadges', otTxt, 'a');otTxt='';">Add</button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="top-tab5" role="tabpanel"
                                    aria-labelledby="tab4-top-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Please specify tags that can be applied to the Routes of Indents in the system
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center">Route Tag</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let rt of lstTabsData?.lstRT; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{rt.name}}</td>
                                                                    <td>
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true" *ngIf="cnfgTagsPrmsn"
                                                                            (click)="onAddDel('routeBadges', rt, 'd')">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstRT?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <input type="text" class="form-control form-control-sm"
                                                                            [(ngModel)]="rtTxt" placeholder="Create custom tag"
                                                                        >
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn btn-sm btn-primary" (click)="onAddDel('routeBadges', rtTxt, 'a');rtTxt='';">Add</button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="top-tab4" role="tabpanel"
                                    aria-labelledby="tab5-top-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Please specify tags that can be applied to the Users in the system
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center">User Tag</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let ut of lstTabsData?.lstUT; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{ut.name}}</td>
                                                                    <td>
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true" *ngIf="cnfgTagsPrmsn"
                                                                            (click)="onAddDel('userBadges', ut, 'd')">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstUT?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <input type="text" class="form-control form-control-sm"
                                                                            [(ngModel)]="utTxt" placeholder="Create custom tag"
                                                                          >
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn btn-sm btn-primary" (click)="onAddDel('userBadges', utTxt, 'a');utTxt='';">Add</button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="top-tab6" role="tabpanel"
                                    aria-labelledby="tab6-top-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Please specify tags that can be applied to the Producers in the system
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center">Producer Tag</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let mt of lstTabsData?.lstMT; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{mt.name}}</td>
                                                                    <td>
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true" *ngIf="cnfgTagsPrmsn"
                                                                            (click)="onAddDel('producerBadges', mt, 'd')">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstMT?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="2">
                                                                        <input type="text" class="form-control form-control-sm"
                                                                            [(ngModel)]="mtTxt" placeholder="Create custom tag"
                                                                            >
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn btn-sm btn-primary" (click)="onAddDel('producerBadges', mtTxt, 'a');mtTxt='';">Add</button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="top-tab7" role="tabpanel"
                                    aria-labelledby="tab7-top-tab">
                                    <div class="card border-0 shadow-sm p-0 m-0">
                                        <div class="card-body p-2">
                                            <div class="small">
                                                Specify the rank of the Facility categories in the Indent to be
                                                rendered in network view
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive mt-2">
                                                        <table class="table table-striped table-sm table-bordered w-50">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center w-24">Item No.</th>
                                                                    <th class="text-center w-31">Facility Tags</th>
                                                                    <th class="text-center w-24">Rank</th>
                                                                    <th class="text-center w-24"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let ir of lstTabsData?.lstIR;let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">{{ir.badgeName}}</td>
                                                                    <td class="text-center" class="text-center">
                                                                        <input type="text" placeholder="" value="{{ir.rank}}"
                                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                                            class="form-control form-control-sm text-center" maxlength="2"
                                                                            (change)="onChngRnk($event, ir.badgeName, i)">
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <i class="fa fa-trash toggle-data" aria-hidden="true" *ngIf="cnfgTagsPrmsn"
                                                                            (click)="onAddDel('storeBadgeRanks', ir, 'd')">
                                                                        </i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstTabsData?.lstIR?.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="4">
                                                                        {{noRcrdTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <ng-template #cstmFclty let-model="item">
                                                                            <span class="font-weight-bold">{{model.name}}</span>
                                                                        </ng-template>
                                                                        <input name="material" type="text" placeholder="Select Facility Name"
                                                                            [typeahead]="lstMoreTags" class="form-control form-control-sm w-48"
                                                                            [typeaheadScrollable]="true" [(ngModel)]="facName" [typeaheadOptionsLimit]="500"
                                                                            [typeaheadItemTemplate]="cstmFclty" [adaptivePosition]="true"
                                                                            (typeaheadOnSelect)="onSlctFclty($event)" autocomplete="off"
                                                                            typeaheadOptionField="name" (change)="onSlctFclty($event)" />
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end mt-3 pb-3">
            <!-- <button type="button" class="btn btn-sm btn-primary" (click)="onSave()" *ngIf="cnfgTagsPrmsn" >Save</button> -->
        </div>
    </div>
</div>