<div class="row">
    <div class="col-xl-12">
        <div class="card border-0 h-100">
            <div class="card-header p-2">
                <h6 class="mb-0">{{strDetails?.name}}</h6>
                <small>
                    {{strDetails?.storeLocation}} <!-- {{strDetails?.districtId}}, {{strDetails?.city}}, {{strDetails?.stateId}}, {{strDetails?.countryId}} -->
                </small>
                <!-- <div class="float-end"><i class="fa fa-external-link" aria-hidden="true"></i></div> -->
            </div>
            <div class="card-body p-2">
                <div class="mb-2">
                    <i class="fa fa-category" aria-hidden="true"></i>
                    <a *ngFor="let sB of strDetails?.storeBadges"><span class="badge bg-secondary">{{sB}}</span>&nbsp;&nbsp;</a>
                </div>
                <div class="mb-2" *ngIf="strDetails?.storeUsers != null">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <span class="badge bg-warning">
                        <a>{{strDetails?.storeUsers | json}}</a>
                    </span>
                </div>
                <div class="mb-2" *ngIf="strDetails?.zeroStockMaterials?.length!=0">
                    <p class="text-danger pntr" href="#divZSM" aria-expanded="false" aria-controls="divZSM" data-toggle="collapse">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        {{strDetails?.zeroStockMaterials?.length}} materials are out of stock
                    </p>
                </div>
                <div class="row collapse" id="divZSM">
                    <div class="col-xl-12">
                        <a *ngFor="let zsm of strDetails?.zeroStockMaterials"><span class="badge bg-info">{{zsm}}</span>&nbsp;&nbsp;</a>
                    </div>
                </div>
            </div>
            <div class="card-footer p-2 small">
                Created on {{strDetails?.createdOn | date: "dd-MM-yyyy hh:mm a"}} by <a>{{strDetails?.createdBy}}</a>,
                Last updated on {{strDetails?.updatedOn | date: "dd-MM-yyyy hh:mm a"}} by <a>{{strDetails?.updatedBy}}</a>
            </div>
        </div>
    </div>
    <div class="col-xl-4" hidden>
        <div class="card border-0 h-100">
            <div class="card-body carousel_box p-2">
                <div id="map-container-google-1" class="z-depth-1-half map-container" style="width: 100%; height: 250px">
                    <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>
                </div>
                <div class="clearfix"></div>
                <div class="float-end mt-2"><span>23.2098, 77.4286</span></div>
            </div>
        </div>
    </div>
</div>
<div class="card mb-0 mt-3">
    <div class="card-body p-2">
        <div class="row text-center">
            <div class="col-xl-6 pt-3">
                <div>
                    <h6 class="mb-0">Stock Management</h6>
                    <small class="text-secondary">Material category(s):
                        {{strDetails?.normalMaterialBadges?.length > 0 ? (strDetails?.normalMaterialBadges | json) : '-'}}
                    </small>
                    <h5 class="text-success">{{strDetails?.normalMaterialPercentage}}%</h5><small class="text-secondary">Available</small>
                </div>
            </div>
            <div class="col-xl-6 pt-3">
                <h6 class="mb-0">Temperature</h6>
                <small class="text-secondary">Exclude temperature state(s): Unknown</small>
                <h5 class="text-secondary">No data to display</h5>
            </div>
        </div>
        <div class="collapse multi-collapse" id="multiCollapseExample_details">
            <div class="row">
                <div class="col-xl-6">
                    <div class="text-center">
                        <!-- <img src="./assets/img/graph.png"> -->
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="text-center">
                        <p>No data to display</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <span class="mb-0 text-center mt-2 mb-2" data-bs-toggle="collapse" href="#multiCollapseExample_details"
            role="button" aria-expanded="false" aria-controls="multiCollapseExample_details">
            <i class="fa fa-chevron-down f-24" aria-hidden="true"></i>
        </span>
    </div>
</div>
<div class="card mb-0 mt-3">
    <div class="card-body p-4 pt-2 pb-2 border-0 row">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                <li class="nav-item"><a class="nav-link nav-link1 f-14 active" id="setup-content-tab" aria-controls="setup-content"
                    data-bs-toggle="tab" href="#setup-content" role="tab" aria-selected="true" #stkTab (click)="onMainTabClk('s')">Stock Management</a></li>
                <li class="nav-item" *ngIf="fcltyDtlViewIndents"><a class="nav-link nav-link1 f-14" id="setup1-content-tab" data-bs-toggle="tab" #indTab
                    href="#setup1-content" role="tab" aria-controls="setup1-content" aria-selected="false" (click)="tabClick('i'); onMainTabClk('i')">Indents</a></li>
                <li class="nav-item" *ngIf="fcltyDtlViewTransctn"><a class="nav-link nav-link1 f-14" id="setup2-content-tab" data-bs-toggle="tab" #txnTab
                    href="#setup2-content" role="tab" aria-controls="setup2-content" aria-selected="false" (click)="tabClick('t'); onMainTabClk('t')">Transactions</a></li>
                <li class="nav-item" *ngIf="fcltyDtlViewMtrl"><a class="nav-link nav-link1 f-14" id="setup3-content-tab" data-bs-toggle="tab" #mtrlTab
                    href="#setup3-content" role="tab" aria-controls="setup3-content" aria-selected="false" (click)="onMainTabClk('m')">Materials</a></li>
                <li class="nav-item" *ngIf="fcltyDtlViewRltnshp"><a class="nav-link nav-link1 f-14" id="setup4-content-tab" data-bs-toggle="tab" #rlnshpTab
                    href="#setup4-content" role="tab" aria-controls="setup4-content" aria-selected="false" (click)="onMainTabClk('r')">Relationship</a></li>
                <li class="nav-item" *ngIf="false"><a class="nav-link nav-link1 f-14" id="setup6-content-tab" data-bs-toggle="tab"
                    href="#setup6-content" role="tab" aria-controls="setup6-content" aria-selected="false" (click)="onMainTabClk('a')">Assets</a></li> <!-- *ngIf="fcltyDtlviewAssets" -->
                <li class="nav-item" *ngIf="fcltyDtlViewPrfl"><a class="nav-link nav-link1 f-14" id="setup7-content-tab" data-bs-toggle="tab"
                    href="#setup7-content" role="tab" aria-controls="setup7-content" aria-selected="false" (click)="onMainTabClk('p')">Profile</a></li>
            </ul>
        </div>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="setup-content" role="tabpanel" aria-labelledby="setup-content-tab">
                <div class="row p-0 mt-3">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="row row mb-2">
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Material Category:</label>
                                        <!-- formControlName="includeProductBadge" -->
                                        <ng-multiselect-dropdown [settings]="msdStngs"
                                            [(ngModel)]="slctdMat" [data]="lstMatTags"
                                            (onSelect)="onMultiSlct('s', 'm', $event)"
                                            (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'm', $event)">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="col-xl-6"></div>
                                    <div class="form-group float-xl-end col-xl-3 mt-5 text-end">
                                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="onStkReset('stk')">Reset</button>
                                        <button class="btn btn-sm btn-primary mb-2" (click)="fltrStkVal()">Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-xl-4 text-start">
                        <div *ngIf="lstStkMtrls.length!=0">{{pageStkEntrs}}</div>
                    </div>
                    <div class="col-xl-4 text-center">
                        
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }" class="clrCde p-1 me-2">Zero Stock</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }" class="clrCde p-1 me-2">Min</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }" class="clrCde p-1 me-2">Max</span>
                    </div>
                    <div class="col-xl-4 text-end">
                        <div class="float-end" *ngIf="lstStkMtrls.length!=0">
                            <pagination-controls previousLabel="" nextLabel="" id="pcSMs"
                                (pageChange)="setPage($event, 'sm')"></pagination-controls>
                        </div>                        
                    </div>
                </div>
                <div class="table_spacing table-responsive mt-0">
                    <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Item No.</th>
                                <th class="text-center" scope="col">Material</th>
                                <th class="text-center" scope="col">Total Stock</th>
                                <th class="text-center" scope="col">&nbsp;</th>
                                <th class="text-center" scope="col">Allocated</th>
                                <th class="text-center" scope="col">Available</th>
                                <th class="text-center" scope="col">In-transit</th>
                                <th class="text-center" scope="col">Min.</th>
                                <th class="text-center" scope="col">Max.</th>
                                <th class="text-center" scope="col">Last updated</th>
                                <th class="text-center" scope="col">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="lstStkMtrls.length!=0">
                            <tr *ngFor="let item of lstStkMtrls | paginate: { itemsPerPage: pgSze, currentPage: crntStkPage,
                                    totalItems: ttlStkItems, id: 'pcSMs' }; let i=index" [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ?
                                    item.stockIndicator.backgroundColor : null, 'color': item.stockIndicator != null ? '#000000' : null }">
                                <td class="text-center">{{(pgSze*(crntStkPage-1))+i+1}}</td>
                                <td>{{item.productName}}</td>
                                <td>{{item.totalStock}}</td>
                                <td title="Batch Details" class="toggle-data">
                                    <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                        [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                        class="expand-row" *ngIf="item.batchManagement"> <i class="fa fa-list-alt"></i>
                                    </a>
                                </td>
                                <td title="Allocated Stock Details" class="toggle-data">
                                    <a (click)="openModal(item.storeId, item.productId, item.productName, 'a')"
                                        [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                        class="expand-row">{{item.allocatedStock}}
                                    </a>
                                </td>
                                <td>{{item.availableStock}}</td>
                                <td title="In-transit Stock Details" class="toggle-data">
                                    <a (click)="openModal(item.storeId, item.productId, item.productName, 'i')"
                                        [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                        class="expand-row">{{item.intransitStock}}
                                    </a>
                                </td>
                                <td title="Min-Max Details" class="toggle-data">
                                    <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                        [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                        class="expand-row">{{item.invMin}}
                                    </a>
                                </td>
                                <td title="Min-Max Details">
                                    <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                        [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                        class="expand-row">{{item.invMax}}
                                    </a>
                                </td>
                                <td>{{item.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}</td>
                                <td title="History Details" class="toggle-data">
                                    <a (click)="openModal(item.storeId, item.productId, item.productName, 'h')"
                                        [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                        class="expand-row"><i class="fa fa-history"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="lstStkMtrls.length==0">
                            <tr>
                                <td colspan="11" class="text-center">
                                    {{noRcrdsTxt}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div><br />
                <div class="row">
                    <div class="col-xl-4 text-start">
                        <div *ngIf="lstStkMtrls.length!=0">{{pageStkEntrs}}</div>
                    </div>
                    <div class="col-xl-4 text-center">
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }" class="clrCde p-1 me-2">Normal</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }" class="clrCde p-1 me-2">Zero Stock</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }" class="clrCde p-1 me-2">Min</span>
                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }" class="clrCde p-1 me-2">Max</span>
                    </div>
                    <div class="col-xl-4 text-end">
                        <div class="float-end" *ngIf="lstStkMtrls.length!=0">
                            <pagination-controls previousLabel="" nextLabel="" id="pcSMs"
                                (pageChange)="setPage($event, 'sm')"></pagination-controls>
                        </div>
                    </div>
                </div><br />
            </div>
            <div class="tab-pane fade" id="setup1-content" role="tabpanel" aria-labelledby="setup1-content-tab">
                <div class="border-0 p-2 row">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                            <li class="nav-item" *ngIf="indntLst"><a class="nav-link nav-link1 f-14 active" id="contact-top-tab-2"
                                data-bs-toggle="tab" href="#top-contact-11" role="tab" (click)="tabClick('ii')" #indLst
                                aria-controls="top-contact-11" aria-selected="false">Indents</a></li>
                            <li class="nav-item" *ngIf="cratIndnt"><a class="nav-link nav-link1 f-14" id="contact-top-tab-3"
                                data-bs-toggle="tab" href="#top-contact-21" role="tab" (click)="tabClick('ci')"
                                aria-controls="top-contact-21" aria-selected="false">Create Indents</a></li>
                        </ul>
                    </div>
                    <div class="tab-content" id="top-tabContent">
                        <div class="tab-pane fade show active" id="top-contact-11" role="tabpanel"
                            aria-labelledby="contact-top-tab-2">
                            <div class="row p-0 mt-2">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <form [formGroup]="orderlstfrm" autocomplete="off" class="p-2">
                                        <div class="row p-0 mt-2">
                                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-xl-12">
                                                        <div class="form-row row mb-2">
                                                            <div class="form-group col-xl-3">
                                                                <label for="textBox">Facility</label>
                                                                <ng-template #cstmStore let-model="item">
                                                                    <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                                                    <span class="fs-12">{{model.location}}</span>
                                                                </ng-template>
                                                                <input name="store" type="text" placeholder="Filter by Facility"
                                                                    formControlName="storeId" [typeahead]="lstStrs" [typeaheadScrollable]="true"
                                                                    [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName" id="Facility"
                                                                    (typeaheadOnSelect)="onChngFclty($event, 'slct')" (change)="onChngFclty($event, 'chng')"
                                                                    [adaptivePosition]="true" [attr.disabled]="(usrStrLen==1||isSUFclty)?true:null" 
                                                                    [typeaheadOptionsLimit]="500" class="form-control form-control-sm" />
                                                            </div>
                                                            <div class="form-group col-xl-3">
                                                                <label for="textBox">Status</label>
                                                                <select id="inputState" class="form-select form-select-sm" formControlName="status">
                                                                    <option value="">All</option>
                                                                    <option *ngFor="let os of orderStatusList" [value]="os.name">{{os.name}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="form-group col-md-3">
                                                                <label for="textBox">From</label>
                                                                <div class="input-group">
                                                                    <!-- [dateCustomClasses]="cstmDt"  -->
                                                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                                                        (bsValueChange)="onChngDT($event)"
                                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                                        onkeypress='return event.charCode == 8' formControlName="fromDate"
                                                                        [(ngModel)]="frmDate" placeholder="From" [maxDate]="dt2day" readonly>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                                                            [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                                                        </button>
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                            (click)="dpFD.hide();clearBSDate('fromDate', 'i')"> <i class="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-3">
                                                                <label for="textBox">To</label>
                                                                <div class="input-group">
                                                                    <!-- [dateCustomClasses]="cstmDt2"  -->
                                                                    <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                                                        (bsValueChange)="onChngDtT($event)"
                                                                        class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                                        onkeypress='return event.charCode == 8' formControlName="toDate"
                                                                        bsDatepicker [minDate]="frmDate" [maxDate]="dt2day" readonly>
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                                                        </button>
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                            (click)="dpED.hide();clearBSDate('toDate', 'i')"> <i class="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-md-3">
                                                                <label for="inputEmail3" class="col-form-label"> Type</label>
                                                                <select id="orderType" class="form-select form-select-sm" formControlName="indentTypeId">
                                                                    <option [value]="ordTY.id" *ngFor="let ordTY of lstOrdTyps">{{ordTY.name}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-xl-12">
                                                        <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                                                            aria-controls="box1">Advance Search</button>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div id="box1" class="collapse">
                                                    <div class="form-row row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Indent Id</label>
                                                            <input type="text" placeholder="Indent Id" class="form-control form-control-sm"
                                                                formControlName="bookingId" id="bookingID">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Issue reference #</label>
                                                            <input type="text" placeholder="Issue reference #" class="form-control form-control-sm"
                                                                formControlName="issueReferenceId" id="issueReference">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Receipt reference #</label>
                                                            <input type="text" placeholder="Receipt reference #" class="form-control form-control-sm"
                                                                formControlName="receiptReferenceId">
                                                        </div>
                                                        <div class="form-group col-xl-3" hidden>
                                                            <label for="textBox">Approval status</label>
                                                            <select id="inputState" class="form-select form-select-sm" formControlName="approvalStatus">
                                                                <option value="null">All</option>
                                                                <option *ngFor="let os of orderStatusList" [value]="os.name">{{os.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-xl-3">
                                                            <span class="pl-xl-3">Facility Category:
                                                                <select class="form-select form-select-sm" formControlName="storeBadgeId">
                                                                    <option value="null">-Select-</option>
                                                                    <option *ngFor="let sb of lstFacTags" [value]="sb.id">{{sb.name}}</option>
                                                                </select>
                                                            </span>
                                                        </div>
                                                        <div class="col-xl-3">
                                                            <span class="pl-xl-3">Indent Category:
                                                                <select class="form-select form-select-sm" formControlName="bookingBadgeId">
                                                                    <option value="null">-Select-</option>
                                                                    <option *ngFor="let sb of lstTags" [value]="sb.id">{{sb.name}}</option>
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-12" *ngIf="indntLst">
                                                        <div class="float-xl-end">
                                                            <button class="btn btn-sm btn-light me-2 mb-2" (click)="onReset()">Reset</button>
                                                            <button class="btn btn-sm btn-primary mb-2" (click)="LoadOrders()">Filter</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="row mt-3">
                                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                                <div class="row mb-2" *ngIf="ordersList?.length!=0">
                                                    <div class="col-xl-4 text-start">
                                                        {{pageILEntrs}}
                                                    </div>
                                                    <div class="col-xl-4 text-center">
                                                        <button class="btn btn-sm btn-success" (click)="onEE()"
                                                            *ngIf="ordersList?.length!=0">
                                                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                                        </button>
                                                    </div>
                                                    <div class="col-xl-4">
                                                        <div class="float-end">
                                                            <pagination-controls previousLabel="" nextLabel="" id="pcIndnts"
                                                                (pageChange)="setPage($event, 'il')"></pagination-controls>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table_spacing table-responsive table-row-spacing">
                                                    <table class="table table-striped table-sm table-bordered" id="table_detail">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th class="text-center">Item No.</th>
                                                                <th class="text-center">Indent Id</th>
                                                                <th class="text-center">Items</th>
                                                                <th class="text-center">Status</th>
                                                                <th class="text-center">Facility</th>
                                                                <th class="text-center">Created</th>
                                                                <th class="text-center">Issuing Facility</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="table_data">
                                                            <tr *ngFor="let item of ordersList | search:searchText:filterMetadata | paginate: {
                                                                itemsPerPage: pgSze, currentPage: crntILPage, totalItems: ttlILItems, id: 'pcIndnts' };
                                                                let i=index"> <!-- (click)="onOrdIdClk(item.bookingId)" -->
                                                                <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                                                <td class="text-center">{{(pgSze*(crntILPage-1))+i+1}}</td>
                                                                <td class="text-center">{{item.bookingId}}</td>
                                                                <td class="text-center">{{item.bookingItemsCount}}</td>
                                                                <td>{{item.status}}</td>
                                                                <td>
                                                                    <div class="small"><a>{{item.recevingStoreName}}</a></div>
                                                                    <div class="small">{{item.recevingStoreLocation}}</div>
                                                                </td>
                                                                <td>
                                                                    <a>{{item.createdBy}}</a>
                                                                    <div class="small">{{item.createdOn | date: "dd/MM/yyyy hh:mm aa"}}</div>
                                                                </td>
                                                                <td>
                                                                    <a>{{item.issuingStoreName}}</a>
                                                                    <div class="small">{{item.issuingStoreLocation}}</div>
                                                                </td>
                                                                <td class="text-center" style="width: 7%;">
                                                                    <span class="f-14">
                                                                        <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.sourceType==1"></i>
                                                                        <i class="fa fa-desktop" aria-hidden="true" *ngIf="item.sourceType==2"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="ordersList?.length==0 || count==0">
                                                            <tr>
                                                                <td colspan="9" class="text-center">
                                                                    {{noRcrdsTxt}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="ordersList?.length!=0">
                                                    <div class="col-xl-6 text-start">
                                                        {{pageILEntrs}}
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="float-end">
                                                            <pagination-controls previousLabel="" nextLabel="" id="pcIndnts"
                                                                (pageChange)="setPage($event, 'il')"></pagination-controls>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="top-contact-21" role="tabpanel"
                            aria-labelledby="contact-top-tab-3">
                            <div class="card mt-0 border-0">
                                <div class="card-body p-2">
                                    <evin-orders-create></evin-orders-create>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="setup2-content" role="tabpanel" aria-labelledby="setup2-content-tab">
                <div class="border-0 mt-2 row">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                            <li class="nav-item" *ngIf="trnsactnsLst"><a class="nav-link nav-link1 f-14 active" id="contact-top-tab-2"
                                data-bs-toggle="tab" href="#top-contact-41" role="tab" aria-controls="top-contact-2"
                                aria-selected="false" (click)="tabClick('tl')" #txnLst>Transactions</a></li>
                            <li class="nav-item" *ngIf="cratTrnsactns"><a class="nav-link nav-link1 f-14" id="contact-top-tab-30"
                                data-bs-toggle="tab" href="#top-contact-42" role="tab" aria-controls="top-contact-3"
                                aria-selected="false" (click)="tabClick('tc')">Create Transaction</a></li>
                        </ul>
                    </div>
                    <div class="tab-content" id="top-tabContent">
                        <div class="tab-pane fade show active" id="top-contact-41" role="tabpanel"
                            aria-labelledby="contact-top-tab-2">
                            <div class="row p-0 mt-3">
                                <div class="row p-2 mt-2">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <form [formGroup]="invtrasLstFrm" autocomplete="off">
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="form-row row mb-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Facility</label>
                                                            <ng-template #cstmStore let-model="item">
                                                                <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                                                <span class="fs-12">{{model.location}}</span>
                                                            </ng-template>
                                                            <input name="store" type="text" placeholder="Filter by Facility" id="Store"
                                                                formControlName="storeId" [typeahead]="lstStrs" [typeaheadScrollable]="true"
                                                                [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                                                (typeaheadOnSelect)="changeFn($event, 'onSlct')" (change)="changeFn($event, 'chng')" 
                                                                [attr.disabled]="(lstStrs.length == 1||isSUFclty)?true : null" [typeaheadOptionsLimit]="500" />
                                                        </div>
                                                        <div class="form-group col-xl-3" *ngIf="!shwAPrds"> <!-- [hidden]="invtrasLstFrm.get('storeId')?.value == null" -->
                                                            <label for="textBox">Material</label>
                                                            <ng-template #cstmProduct let-model="item">
                                                                <span class="font-weight-bold">{{model.productName}}</span> <br />
                                                                <span class="fs-12">{{model.location}}</span>
                                                            </ng-template>
                                                            <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                                                formControlName="productId" [typeahead]="lstAllMats" [typeaheadScrollable]="true"
                                                                [typeaheadItemTemplate]="cstmProduct" typeaheadOptionField="productName" [typeaheadOptionsLimit]="500"
                                                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                                                (typeaheadOnSelect)="changeFn2($event, 'byStr', 'onSlct')" (change)="changeFn2($event, 'byStr', 'chng')" />
                                                        </div>
                                                        <div class="form-group col-xl-3" *ngIf="shwAPrds"> <!-- [hidden]="invtrasLstFrm.get('storeId')?.value != null" -->
                                                            <label for="textBox">Material</label>
                                                            <ng-template #cstmProduct1 let-model="item">
                                                                <span class="font-weight-bold">{{model.name}}</span> <br />
                                                            </ng-template>
                                                            <input name="product" type="text" placeholder="Filter by Material" id="Product"
                                                                formControlName="productId" [typeahead]="lstAllMats1" [typeaheadScrollable]="true"
                                                                [typeaheadItemTemplate]="cstmProduct1" typeaheadOptionField="name"
                                                                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                                                                (typeaheadOnSelect)="changeFn2($event, 'all', 'onSlct')" (change)="changeFn2($event, 'all', 'chng')"
                                                                [typeaheadOptionsLimit]="500">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Transaction Type</label>
                                                            <select id="inputState" class="form-select form-select-sm" formControlName="txnType">
                                                                <option value=''>All</option>
                                                                <option [value]="txnTY.id" *ngFor="let txnTY of lstTxnTyps">{{txnTY.name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-md-3">
                                                        <label for="textBox">From</label>
                                                        <div class="input-group">
                                                            <!-- [dateCustomClasses]="cstmDt"  -->
                                                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                                                placeholder="From" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                                (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8'
                                                                formControlName="txnsFromDate" [(ngModel)]="frmDate" [maxDate]="dt2day" readonly>
                                                            <div class="input-group-append">
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                                                    [attr.aria-expanded]="dpFD.isOpen">
                                                                    <i class="fa fa-calendar"></i>
                                                                </button>
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                    (click)="dpFD.hide();clearBSDate('txnsFromDate', 't')">
                                                                    <i class="fa fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label for="textBox">To</label>
                                                        <div class="input-group">
                                                            <!-- [dateCustomClasses]="cstmDt"  -->
                                                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                                class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker"
                                                                readonly [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                                (bsValueChange)="onChngDtT($event)" onkeypress='return event.charCode == 8'
                                                                formControlName="txnsToDate" placeholder="To" [minDate]="frmDate" [maxDate]="dt2day">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                                                    [attr.aria-expanded]="dpTD.isOpen">
                                                                    <i class="fa fa-calendar"></i>
                                                                </button>
                                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                    (click)="dpTD.hide();clearBSDate('txnsToDate', 't')">
                                                                    <i class="fa fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-3 pt-4">
                                                        <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                                                            aria-controls="box1" (click)="onAS()">Advance Search</button>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <input type="checkbox" [checked]="isInitTxnDate" (change)="isInitTxnDate=!isInitTxnDate">
                                                    <label class="ms-2" for="scales">Filter by date of actual transaction</label>
                                                </div>
                                                <div id="box1" class="collapse">
                                                    <div class="form-row row mb-2">
                                                        <div class="form-group col-md-3">
                                                            <label for="textBox">State</label>
                                                            <select formControlName="state" class="form-select form-select-sm"
                                                                (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                                                                <option value="null">-Select-</option>
                                                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label for="textBox">District</label>
                                                            <select formControlName="district" class="form-select form-select-sm"
                                                                (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                                                <option value="null">-Select-</option>
                                                                <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label for="textBox">Taluk/Block</label>
                                                            <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                                                <option value="null">-Select-</option>
                                                                <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2 mt-3">
                                                        <div class="col-xl-6">
                                                            <span class="pl-xl-3">Facility Category:
                                                                <span *ngIf="isFacAll">ALL</span>
                                                                <span *ngIf="!isFacAll && lstShowFacTags.length!=0">
                                                                    <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                                                        {{fc.name}}
                                                                    </span>
                                                                </span>
                                                            </span>&nbsp;
                                                            <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button"
                                                                aria-expanded="false" aria-controls="multiCollapseExample1">
                                                                <i class="fa fa-filter"></i>
                                                            </span>
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                                        <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                                            <div class="row">
                                                                                <div class="col-xl-6 col-sm-12">
                                                                                    <div class="row">
                                                                                        <div class="">
                                                                                            <ng-multiselect-dropdown [settings]="msdStngs"
                                                                                                [data]="lstFCF" formControlName="storeBadge"
                                                                                                (onSelect)="onMultiSlctT('s', 'f', $event)"
                                                                                                (onSelectAll)="onMultiSlctT('a', 'f', $event)"
                                                                                                (onDeSelect)="onMultiSlctT('r', 'f', $event)"
                                                                                                (onDeSelectAll)="onMultiSlctT('d', 'f', $event)"
                                                                                                [(ngModel)]="slctdFac">
                                                                                            </ng-multiselect-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-xl-6 col-sm-12">
                                                                                    <div class="float-end">
                                                                                        <button class="btn btn-sm btn-outline-light me-2"
                                                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                                                            aria-controls="multiCollapseExample1" role="button"
                                                                                            href="#multiCollapseExample1" (click)="onResetTags('f')">Reset</button>
                                                                                        <button class="btn btn-sm btn-primary me-2" role="button"
                                                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                                                            aria-controls="multiCollapseExample1"
                                                                                            href="#multiCollapseExample1" (click)="onTagsSave('f')">Save</button>
                                                                                        <button class="btn btn-sm btn-light" (click)="onCncl('f')"
                                                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                                                            aria-controls="multiCollapseExample1" role="button"
                                                                                            href="#multiCollapseExample1">Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <span class="pl-xl-3">Material Category:
                                                                <span *ngIf="isMatAll">ALL</span>
                                                                <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                                                    <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                                                        {{mc.name}}
                                                                    </span>
                                                                </span>
                                                            </span>&nbsp;
                                                            <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button"
                                                                aria-expanded="false" aria-controls="multiCollapseExample2">
                                                                <i class="fa fa-filter"></i>
                                                            </span>
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="collapse multi-collapse" id="multiCollapseExample2">
                                                                        <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                                            <div class="row">
                                                                                <div class="col-xl-6 col-sm-12">
                                                                                    <div class="row">
                                                                                        <div class="">
                                                                                            <ng-multiselect-dropdown [settings]="msdStngs"
                                                                                                [data]="lstMCF" formControlName="productBadge"
                                                                                                (onSelect)="onMultiSlctT('s', 'm', $event)"
                                                                                                (onSelectAll)="onMultiSlctT('a', 'm', $event)"
                                                                                                (onDeSelect)="onMultiSlctT('r', 'm', $event)"
                                                                                                (onDeSelectAll)="onMultiSlctT('d', 'm', $event)"
                                                                                                [(ngModel)]="slctdMat">
                                                                                            </ng-multiselect-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-xl-6 col-sm-12">
                                                                                    <div class="float-end">
                                                                                        <button class="btn btn-sm btn-outline-light me-2"
                                                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                                                            aria-controls="multiCollapseExample2" role="button"
                                                                                            href="#multiCollapseExample2" (click)="onResetTags('m')">Reset</button>
                                                                                        <button class="btn btn-sm btn-primary me-2" role="button"
                                                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                                                            aria-controls="multiCollapseExample2"
                                                                                            href="#multiCollapseExample2" (click)="onTagsSave('m')">Save</button>
                                                                                        <button class="btn btn-sm btn-light" (click)="onCncl('m')"
                                                                                            data-bs-toggle="collapse" aria-expanded="false"
                                                                                            aria-controls="multiCollapseExample2" role="button"
                                                                                            href="#multiCollapseExample2">Cancel</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2 mt-3">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Transaction reason:</label>
                                                            <select class="form-select form-select-sm" formControlName="txnReason">
                                                                <option value=''>All</option>
                                                                <option *ngFor="let rs of txnRsns" [value]="rs.reasonId">{{rs.reasonName}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12" *ngIf="trnsactnsLst">
                                                    <div class="float-xl-end">
                                                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="isInitTxnDate=false;onResetTags('f');onResetTags('m');onReset()">Reset</button>
                                                        <button class="btn btn-sm btn-primary mb-2" (click)="isShowDAT=false;LoadStoreTransactions()">Filter</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <hr />
                                <div class="row p-2 mt-3">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="row" *ngIf="lstTrans.length!=0">
                                            <div class="col-xl-4 text-start">
                                                {{pageTransEntrs}}
                                            </div>
                                            <div class="col-xl-4 text-center">
                                                <div class="row">
                                                    <div class="col-xl-4">
                                                        <button class="btn btn-sm btn-success" (click)="onEE('t')">
                                                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                                        </button>
                                                    </div>
                                                    <div class="col-xl-8">
                                                        <select class="form-select form-select-sm" id="ee">
                                                            <option value="nb">Export without batch</option>
                                                            <option value="wb">Export with batch</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4">
                                                <div class="float-end">
                                                    <pagination-controls previousLabel="" nextLabel="" id="pcTrans"
                                                        (pageChange)="setPage($event, 't')"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table_spacing table-responsive table-row-spacing">
                                            <table class="table table-striped table-sm table-bordered horizontal-scroll scroll-demo" id="table_detail">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Item No.</th>
                                                        <th class="text-center">Facility</th>
                                                        <th class="text-center">Material</th>
                                                        <th class="text-center">Opening stock</th>
                                                        <th class="text-center">Operation</th>
                                                        <th class="text-center">Quantity</th>
                                                        <th class="text-center">&nbsp;</th>
                                                        <th class="text-center">Closing stock</th>
                                                        <th class="text-center" *ngIf="isShowDAT">Date of actual transaction</th>
                                                        <th class="text-center">Updated on</th>
                                                        <th class="text-center">Updated by</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngFor="let item of lstTrans | paginate: { itemsPerPage: pgSze, currentPage: crntTransPage,
                                                        totalItems: ttlTransItems, id: 'pcTrans' }; let i=index" [hidden]="lstTrans.length==0">
                                                    <tr>
                                                        <td class="text-center">{{(pgSze*(crntTransPage-1))+i+1}}</td>
                                                        <td>
                                                            <div><a>{{item.storeDto.storeName}}</a></div>
                                                            <span>{{item.storeLocation}}</span>
                                                        </td>
                                                        <td>
                                                            <div><a>{{item.storeDto.productName}}</a></div>
                                                            <span *ngIf="item.materialStatus != null && item.materialStatus != ''">
                                                                <span class="font-wt">Status:</span>&nbsp;{{item.materialStatus}}
                                                            </span>
                                                        </td>
                                                        <td>{{item.openingStock}}</td>
                                                        <td>
                                                            <div>{{item.transactionTypeOperation}}</div>
                                                            <span *ngIf="item.transactionTypeOperation == 'Issues/Net Utilization' || 
                                                                item.transactionTypeOperation == 'Transfers' || item.transactionTypeOperation == 'Stock-In'">
                                                                <span *ngIf="item?.storeDto?.rcvngStoreName != null" class="font-wt">
                                                                    {{item.transactionTypeOperation == 'Issues/Net Utilization' ? 'To:' : 
                                                                    item.transactionTypeOperation == 'Transfers' ? 'To:' : 
                                                                    item.transactionTypeOperation == 'Stock-In' ? 'From:' : '' }}</span>&nbsp;
                                                                <span>{{item?.storeDto?.rcvngStoreName}}</span><br />
                                                            </span>
                                                            <span *ngIf="item?.transactionReason != '' && item?.transactionReason != null">
                                                                <span class="font-wt">Reason:</span>&nbsp;<span>{{item?.transactionReason}}</span>
                                                            </span>
                                                        </td>
                                                        <td>{{item.quantity}}</td>
                                                        <td>
                                                            <a (click)="item.isB=!item.isB;LoadBatches(i, item.storeDto.storeId, item.storeDto.productId, item.txnId)"
                                                                class="expand-row" *ngIf="item.productDto.isBatchEnabled"><i class="fa fa-list-alt"></i>
                                                            </a>
                                                        </td>
                                                        <td>{{item.closingStock}}</td>
                                                        <td *ngIf="isShowDAT">{{item.intialTxnDate != null ? (item.intialTxnDate | date: "dd/MM/yyyy") : ''}}</td>
                                                        <td>{{item.timeStamp | date: "dd/MM/yyyy hh:mm aa"}}</td>
                                                        <td><a>{{item.firstName}}</a></td>
                                                        <td class="text-center">
                                                            <span class="f-14">
                                                                <i class="fa fa-mobile" aria-hidden="true" *ngIf="item.sourceType==1"></i>
                                                                <i class="fa fa-desktop" aria-hidden="true" *ngIf="item.sourceType==2"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr class="sub-table no-bg" *ngIf="item.isB">
                                                        <td align="center" colspan="11">
                                                            <div class="card m-3 p-0 border-0 rounded-0">
                                                                <div class="card-header p-2">
                                                                    <div class="float-start">
                                                                        <span class="font-weight-bold pt-3"> Batches of {{item.storeDto.productName}}</span>
                                                                    </div>
                                                                    <div class="float-end">
                                                                        <button class="btn btn-sm rounded bg-light p-0" data-toggle="tooltip"
                                                                            data-placement="top" title="close">
                                                                            <a class="expand-row" *ngIf="item.isB" (click)="item.isB=!item.isB">
                                                                                <i class="fa fa-times-circle"></i>
                                                                            </a>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="table_spacing m-3 mb-0 table-responsive">
                                                                    <table class="table table-striped table-sm table-bordered">
                                                                        <thead class="bg-dark text-white">
                                                                            <tr>
                                                                                <th class="text-center">Item No.</th>
                                                                                <th class="text-center">Batch ID </th>
                                                                                <th class="text-center">Manufactured Date (dd/MM/yyyy)</th>
                                                                                <th class="text-center">Manufacturer</th>
                                                                                <th class="text-center">Expiry Date (dd/MM/yyyy)</th>
                                                                                <th class="text-center">Opening stock</th>
                                                                                <th class="text-center">Closing stock</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody *ngIf="item?.lstBats?.length!=0">
                                                                            <tr *ngFor="let bat of item.lstBats;let j=index">
                                                                                <td class="text-center">{{j+1}}</td>
                                                                                <td>{{bat.batchNo}}</td> <!-- <td>{{bat.batch_no}}</td> -->
                                                                                <td>{{bat.manufacturedDate | date: "dd/MM/yyyy"}}</td> <!-- <td>{{bat.manufactured_date | date: "dd/MM/yyyy"}}</td> -->
                                                                                <td>{{bat.manufacturer}}</td> <!-- <td>{{bat.batch_producer_name}}</td> -->
                                                                                <td>{{bat.expiryDate | date: "dd/MM/yyyy"}}</td> <!-- <td>{{bat.expiry_date | date: "dd/MM/yyyy"}}</td> -->
                                                                                <td>{{bat.openingBatchStock}}</td> <!-- <td>{{bat.totalStock}}</td> -->
                                                                                <td>{{bat.closingBatchStock}}</td> <!-- <td>{{bat.available_stock}}</td> -->
                                                                            </tr>
                                                                        </tbody>
                                                                        <tbody [hidden]="item.lstBats?.length!=0">
                                                                            <tr>
                                                                                <td colspan="7" class="text-center">{{noRcrdsTxt}}</td>
                                                                            </tr>
                                                                        </tbody><br />
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstTrans.length==0">
                                                    <tr>
                                                        <td colspan="10" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstTrans.length!=0">
                                            <div class="col-xl-6 text-start">
                                                {{pageTransEntrs}}
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="float-end">
                                                    <pagination-controls previousLabel="" nextLabel="" id="pcTrans"
                                                        (pageChange)="setPage($event, 't')"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="top-contact-42" role="tabpanel"
                            aria-labelledby="contact-top-tab-30">
                            <div class="card mt-3">
                                <div class="card-body p-2">
                                    <evin-create-transactions></evin-create-transactions>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="setup3-content" role="tabpanel" aria-labelledby="setup3-content-tab">
                <form [formGroup]="facltyDtlsFrm" autocomplete="off">  
                    <div [hidden]="!isShowMat">
                        <div class="row mt-3">
                            <div class="form-group col-xl-3">
                                <label for="textBox">Material Tag:</label>
                                <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdMat" [data]="lstMatTagsStk"
                                    formControlName="includeProductBadge" (onSelect)="onMultiSlct('s', 'stk', $event)"
                                    (onSelectAll)="onMultiSlct('a', 'stk', $event)" (onDeSelect)="onMultiSlct('r', 'stk', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'stk', $event)">
                                </ng-multiselect-dropdown> -->
                                <select formControlName="includeProductBadge" (change)="GetMatLst()"
                                    class="form-select form-select-sm" [(ngModel)]="slctMat">
                                    <option value=''>Select</option>
                                    <option *ngFor="let pc of lstMatTagsStk" [value]="pc.id">{{pc.name}}</option>
                                </select>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="col-xl-4 text-start">
                                        <div *ngIf="mtrlGtLst.length!=0">{{pageMatEntrs}}</div>
                                    </div>
                                    <div class="col-xl-4" *ngIf="fcltyDtlAddMtrl">
                                        <span style="display: inline-block;">
                                            <button class="btn btn-sm btn-primary ms-2 me-2"
                                                (click)="isShowMat=false;iCatId=null;isEdtAd=false;onAddMat();">
                                                <i class="fa fa-plus" aria-hidden="true"></i> Add
                                            </button>
                                        </span>
                                        <span style="display: inline-block;">
                                            <button class="btn btn-sm btn-light me-2" (click)="GetMatLst()">
                                                <i class="fa fa-refresh" aria-hidden="true"></i> Refresh
                                            </button>
                                        </span>
                                    </div>
                                    <div class="col-xl-4 text-start">
                                        <div class="float-end" *ngIf="mtrlGtLst.length!=0">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'm')" id="pcMats"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table_spacing table-responsive mt-2">
                            <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                                <thead>
                                    <tr>
                                        <th scope="col">Item No.</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Min</th>
                                        <th scope="col">Max</th>
                                        <th scope="col">Daily consumption rate</th>
                                        <th scope="col">Temperature Min.</th>
                                        <th scope="col">Temperature Max.</th>
                                        <th scope="col">Updated by</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="mtrlGtLst.length != 0">
                                    <tr *ngFor="let mat of mtrlGtLst | paginate: { itemsPerPage: pgSze, currentPage: crntMatPage,
                                            totalItems: ttlItemsMat, id: 'pcMats' }; let i=index">
                                        <td class="text-center">{{(pgSze*(crntMatPage-1))+i+1}}</td>
                                        <td><a>{{mat.productname}}</a></td>
                                        <td class="text-center">{{mat.minstock}}</td>
                                        <td class="text-center">{{mat.maxstock}}</td>
                                        <td class="text-center">{{mat.dailyconsumptionrate}}</td>
                                        <td class="text-center">{{mat.min}}</td>
                                        <td class="text-center">{{mat.max}}</td>
                                        <td class="text-center">{{mat.updatedBy}}</td>
                                        <td class="text-center">
                                            <a *ngIf="fcltyDtlEdtMtrl" (click)="isShowMat=false;isEdtAd=true;iCatId=mat.icatalogue_id;onMatEdit(mat);"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                            <a *ngIf="fcltyDtlDltMtrl" (click)="onMatDel(mat.icatalogue_id)"><i class="fa fa-trash"></i></a>
                                        </td>
                                        <!-- <td class="text-center" (click)="isShowMat=false;onMatEdit(mat);"><i class="fa fa-pencil"></i></td>
                                        <td class="text-center" (click)="onMatDel(mat.icatalogue_id)"><i class="fa fa-trash"></i></td> -->
                                    </tr>
                                </tbody>
                                <tbody *ngIf="mtrlGtLst.length == 0">
                                    <tr class="text-center">
                                        <td colspan="8">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="mtrlGtLst?.length!=0">
                            <div class="col-xl-4 mb-2 text-start">
                                {{pageMatEntrs}}
                            </div>
                            <div class="col-xl-4 text-center"></div>
                            <div class="col-lg-4">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'm')" id="pcMats"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2" [hidden]="isShowMat">
                        <div class="row">
                            <!-- <div class="form-group col-xl-3">
                                <label>Material <span class="text-danger">*</span></label>
                                <div>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.name}}</span><br />
                                    </ng-template>
                                    <input name="material" type="text" placeholder="Select Material"
                                        [typeahead]="lstFreeMtrls" autocomplete="off" [typeaheadScrollable]="true"
                                        formControlName="productId" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="name" [adaptivePosition]="true" id="prdtName"
                                        (typeaheadOnSelect)="onChngTAH($event)" (change)="onChngTAH($event)" class="form-control form-control-sm"
                                        [attr.disabled]="isEdtAd ? true : null" [typeaheadOptionsLimit]="500" 
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }" />
                                    <div *ngIf="isSbmtd && fc.productId.errors" class="invalid-feedback">
                                        <div *ngIf="fc.productId.errors?.required">Material is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-1">
                                <label for="min">Min</label>
                                <input type="text" class="form-control form-control-sm" id="min" formControlName="minStock"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                    <!-- [ngClass]="{ 'is-invalid': isSbmtd && fc.minStock.errors }" 
                                <div *ngIf="isSbmtd && fc.minStock.errors" class="invalid-feedback">
                                    <div *ngIf="fc.minStock.errors?.required">Min stock is required</div>
                                </div> 
                            </div>
                            <div class="form-group col-xl-1">
                                <label for="max">Max</label>
                                <input type="text" id="max" class="form-control form-control-sm" formControlName="maxStock"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                    <!-- [ngClass]="{ 'is-invalid': isSbmtd && fc.maxStock.errors }" 
                                <div *ngIf="isSbmtd && fc.maxStock.errors" class="invalid-feedback">
                                    <div *ngIf="fc.maxStock.errors?.required">Max stock is required</div> 
                                </div>
                            </div>
                            <div class="col-xl-4"></div> -->
                            <div class="table_spacing table-responsive">
                                <table class="table table-striped table-sm table-bordered"  formArrayName="mtrlFrm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input class="form-check-input" type="checkbox" id="flexCheckDefault11"
                                                    (click)="onSlctAllMtrl($event)" *ngIf="GetMatrls().controls.length!=0">
                                            </th>
                                            <th scope="col">Item No.</th>
                                            <th scope="col">Material Name</th>
                                            <th scope="col">Min</th>
                                            <th scope="col">Max</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- <tr *ngFor="let item of lstFreeMtrls; let index=index"> -->
                                        <tr *ngFor="let item of GetMatrls().controls; let tabIndx=index" [formGroupName]="tabIndx">
                                            <td>
                                                <input type="checkbox" class="form-check-input" id="flexCheckDefault12"
                                                    (change)="onChkChnge($event, item, tabIndx+1)" name="chk">
                                            </td>
                                            <td>{{tabIndx+1}}</td>
                                            <td>{{item?.get('name')?.value}}</td>
                                            <td>
                                                <input type="text" placeholder="Min" class="form-control form-control-sm"
                                                    formControlName="minValue" (keyup)="onEditQty($event, item, tabIndx+1)"
                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                            </td>
                                            <td>
                                                <input type="text" placeholder="Max" formControlName="maxValue"
                                                    class="form-control form-control-sm" (keyup)="onEditQty($event, item, tabIndx+1)"
                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr *ngIf="GetMatrls().controls.length==0">
                                            <td colspan="5" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-xl-12 mt-4">
                                <div class="float-xl-end p-3">
                                    <button class="btn btn-sm btn-light mb-2" (click)="isShowMat=true;isSbmtd=false">Cancel</button>
                                    <button class="btn btn-sm btn-primary me-2 mb-2" (click)="addMultplMtrl()">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="tab-pane fade" id="setup4-content" role="tabpanel" aria-labelledby="setup4-content-tab">
                <div class="row mt-3" [hidden]="!addStatus">
                    <div class="mb-2" *ngIf="fcltyDtlAddRltnshp">
                        <button class="btn btn-sm btn-primary mb-2 me-2" (click)="addStatus=false; onAddRlnFac()">
                            <i class="fa fa-plus" aria-hidden="true"></i> Add</button>
                    </div>
                    <hr />
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                            <li class="nav-item"><a class="nav-link nav-link1 f-14 active" id="contact-top-tab-2"
                                data-bs-toggle="tab" href="#top-contact-44" role="tab" aria-controls="top-contact-2"
                                aria-selected="false">Receiving facilities <span
                                class="badge bg-secondary">{{RecFacLst.length}}</span></a>
                            </li>
                            <li class="nav-item"><a class="nav-link nav-link1 f-14" id="contact-top-tab-3"
                                data-bs-toggle="tab" href="#top-contact-45" role="tab" aria-controls="top-contact-3"
                                aria-selected="false">Issuing facilities <span
                                class="badge bg-secondary">{{IssuFaciLst.length}}</span></a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content" id="top-tabContent">
                        <div class="tab-pane fade show active" id="top-contact-44" role="tabpanel"
                            aria-labelledby="contact-top-tab-2">
                            <div class="row ">
                                <div class="col-xl-12">
                                    <div class="row mt-3">
                                        <div class="form-group col-md-6">
                                            <div class="row">
                                                <label for="textBox" class="col-xl-3 mt-2">Facility category:</label>
                                                <div class="col-xl-4">
                                                    <select class="form-select form-select-sm" [(ngModel)]="rlnFacCatId" (change)="rlnFacChange($event, 'c')">
                                                        <option value="">All</option>
                                                        <option *ngFor="let fc of lstFacTags" [value]="fc.id">{{fc.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-4"></div>
                                        <div class="col-xl-2 text-end">
                                            <div class="float-xl-end">
                                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="resetFacCatRln('c')">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="table_spacing table-responsive">
                                <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                                    <thead>
                                        <tr>
                                            <th scope="col">Item No.</th>
                                            <th scope="col">Receiving facility</th>
                                            <th scope="col">Facility Category</th>
                                            <th scope="col">Location</th>    
                                            <th></th>                                     
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of RecFacLst; let index=index">
                                            <td>{{index+1}}</td>
                                            <td><a>{{item.mappedStoreName}}</a></td>
                                            <td>{{item.mappedStoreBadgeName}}</td>
                                            <td>{{item.mappedLocation}}</td>
                                            <td><i class="fa fa-trash" (click)="delRlnMpngStre(item.storeMappingId, 'c')"></i></td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr *ngIf="RecFacLst.length == 0">
                                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="float-xl-end">
                                        <button class="btn btn-sm btn-primary mb-2">Save route</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="tab-pane fade" id="top-contact-45" role="tabpanel"
                            aria-labelledby="contact-top-tab-3">
                            <div class="row mt-3">
                                <div class="form-group col-md-6">
                                    <div class="row">
                                        <label for="textBox" class="col-xl-3 mt-2">Facility category:</label>
                                        <div class="col-xl-6">
                                            <select class="form-select form-select-sm" [(ngModel)]="issFacSlctdId" (change)="rlnFacChange($event, 'v')">
                                                <option value="">All</option>
                                                <option *ngFor="let ft of lstFacTags" [value]="ft.id">{{ft.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-4"></div>
                                <div class="col-xl-2">
                                    <div class="float-xl-end">
                                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="resetFacCatRln('v')">Reset</button>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="table_spacing table-responsive mt-4">
                                <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                                    <thead>
                                        <tr>
                                            <th class="text-center" scope="col">Item No.</th>
                                            <th class="text-center" scope="col">Issue facility</th>
                                            <th class="text-center" scope="col">Facility Category</th>
                                            <th class="text-center" scope="col">Location</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of IssuFaciLst; let index=index">
                                            <td class="text-center">{{index+1}}</td>
                                            <td><a>{{item.mappedStoreName}}</a></td>
                                            <td>{{item.mappedStoreBadgeName}}</td>
                                            <td>{{item.mappedLocation}}</td>
                                            <td><i class="fa fa-trash" (click)="delRlnMpngStre(item.storeMappingId, 'v')"></i></td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr *ngIf="IssuFaciLst.length == 0">
                                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="float-xl-end">
                                        <button class="btn btn-sm btn-primary mb-2">Save route</button>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="card p-3" [hidden]="addStatus">
                    <div class="card-body p-0">
                        <h6 class="mb-0 border-bottom pt-2 pb-2">Add Relationships To {{strDetails?.name}}</h6>
                        <div class="row p-0 mt-3">
                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="row mb-2">
                                            <div class="form-group col-xl-3">
                                                <label for="textBox">Relation Type</label>
                                                <select class="form-select form-select-sm" id="rltnType"
                                                    (change)="onChange($event)">
                                                    <option value="c">Receiving facility</option>
                                                    <option value="v">Issuing facility</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="float-xl-end">
                                                    <button class="btn btn-sm btn-primary me-2 mb-2"
                                                        (click)="createStrRelation()">Submit</button>
                                                    <button class="btn btn-sm btn-light mb-2"
                                                        (click)="addStatus=true">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 class="mb-0 border-bottom mt-3 pb-2">Related facility</h6>
                        <div class="row mt-3">
                            <div class="form-group col-md-4 mb-2">
                                <label style="display: inline-block;">Facility</label>
                                <ng-template #cstmStore let-model="item">
                                    <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                    <span class="fs-12">{{model.location}}</span>
                                </ng-template>
                                <!-- formControlName="storeId"  -->
                                <input name="store" type="text" placeholder="Filter by Facility" class="form-control form-control-sm"
                                    [typeahead]="lstStrs" [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmStore"
                                    typeaheadOptionField="storeName" id="Facility" [adaptivePosition]="true" [(ngModel)]="rsFacId"
                                    [attr.disabled]="usrStrLen==1?true:null" (typeaheadOnSelect)="resStrChng($event)" 
                                    (change)="resStrChng($event)"  [typeaheadOptionsLimit]="500" autocomplete="off"/>
                            </div>
                            <div class="form-group col-md-4 mb-2">
                                <label for="textBox">Facility category:</label>
                                <select class="form-select form-select-sm col-sm-4" [(ngModel)]="rsFacCatId">
                                    <option value="">All</option>
                                    <option [value]="it.id" *ngFor="let it of lstFacTags">{{it.name}}</option>
                                </select>
                            </div>
                            <div class="col-xl-4 pt_2">
                                <div class="float-end">
                                    <button class="btn btn-sm btn-primary me-2 mb-2" (click)="rltdFacStrsFltr()">Filter</button>
                                    <button class="btn btn-sm btn-light me-2 mb-2" (click)="resetFcaFlds()">Reset</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row ps-0 pe-0 pt-3" *ngIf="lstAllFacilities?.length!=0">
                                    <div class="col-xl-4 mb-0 text-start">
                                        {{pageRlnEntrs}}
                                    </div>
                                    <div class="col-xl-4 text-center"></div>
                                    <div class="col-lg-4">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'R')" id="pcRlns"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                                <div class="table_spacing table-responsive">
                                    <table class="table table-striped table-sm table-bordered"
                                        style="min-width: 1100px;">
                                        <thead>
                                            <tr>
                                                <th scope="row">
                                                    <input class="form-check-input" type="checkbox" id="flexCheckDefault11"
                                                        (click)="onSlctAll($event)">
                                                </th>
                                                <th scope="col">Item No.</th>
                                                <th scope="col">Facility</th>
                                                <th scope="col">Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of lstAllFacilities | paginate: { itemsPerPage: pgSze, currentPage: crntRlnPage,
                                                totalItems: ttlItemsRln, id: 'pcRlns' }; let indx=index">
                                                <td scope="row"><input class="form-check-input" type="checkbox" id="flexCheckDefault11" (click)="onSlct($event, item)"
                                                        [checked]="item.flag"></td>
                                                <td class="text-center">{{(pgSze*(crntRlnPage-1))+indx+1}}</td>
                                                <td>{{item.storeName}}</td>
                                                <td>{{item.location}}</td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngIf="lstAllFacilities.length == 0">
                                                <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstAllFacilities?.length!=0">
                                    <div class="col-xl-4 mb-2 text-start">
                                        {{pageRlnEntrs}}
                                    </div>
                                    <div class="col-xl-4 text-center"></div>
                                    <div class="col-lg-4">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'R')" id="pcRlns"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="setup6-content" role="tabpanel" aria-labelledby="setup6-content-tab">
                <!-- <form [formGroup]="assetslstfrm" autocomplete="off">
                    <div class="card p-0 border-0">
                        <div class="card-body pt-4">
                            <div class="row p-0 mt-2">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="row mb-2">
                                                <div class="row">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Facility</label>
                                                        <ng-template #cstmStore let-model="item">
                                                            <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                                            <span class="fs-12">{{model.location}}</span>
                                                        </ng-template>
                                                        <input name="store" type="text" placeholder="Filter by Facility"
                                                            class="form-control form-control-sm" formControlName="storeId"
                                                            [typeahead]="lstStrs" [typeaheadScrollable]="true"
                                                            [typeaheadOptionsLimit]="500" [typeaheadItemTemplate]="cstmStore"
                                                            typeaheadOptionField="storeName" id="Store"
                                                            (typeaheadOnSelect)="onChngFcltyCCE($event, 'slct')"
                                                            (change)="onChngFcltyCCE($event, 'chng')" [adaptivePosition]="true" />
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Serial number</label>
                                                        <input type="text" placeholder="Serial number" formControlName="serialNumber"
                                                            class="form-control form-control-sm" id="" (change)="onChngSNO()"
                                                            onkeypress="return event.charCode != 32">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Type</label>
                                                        <ng-multiselect-dropdown [settings]="msdStngs" [data]="astTypLst"
                                                            (onSelect)="onAsstTypeChng('asetTypeId', $event, 'os')"
                                                            (onSelectAll)="onAsstTypeChng('asetTypeId', $event, 'osa')"
                                                            (onDeSelect)="onAsstTypeChng('asetTypeId', $event, 'ds')"
                                                            (onDeSelectAll)="onAsstTypeChng('asetTypeId', $event, 'dsa')"
                                                            formControlName="asetTypeId" [(ngModel)]="slctdAsstTypes">
                                                        </ng-multiselect-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="row">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Relationships</label>
                                                        <select class="form-select form-select-sm" formControlName="relationships"
                                                            (change)="onChngReltn($event)">
                                                            <option value="">All assets</option>
                                                            <option value="1">Assets with relationships</option>
                                                            <option value="2">Assets without relationships</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Working status</label>
                                                        <select class="form-select form-select-sm" formControlName="approvalStatus">
                                                            <option value="">All</option>
                                                            <option *ngFor="let os of astStatusList" [value]="os.id">{{os.name}}</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-xl-3" *ngIf="shwAlrmDrpdwn">
                                                        <label for="textBox">Alarms</label>
                                                        <select class="form-select form-select-sm" formControlName="alarms"
                                                            (click)="selectedAlaram()">
                                                            <option value="0">All</option>
                                                            <option value="1">Device alarms</option>
                                                            <option value="2">No data</option>
                                                            <option value="3">Normal items</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-xl-3" *ngIf="dratn">
                                                        <label for="textBox">Duration</label>
                                                        <select class="form-select form-select-sm" formControlName="duration">
                                                            <option value="">Now</option>
                                                            <option value="7">7 Days</option>
                                                            <option value="30">30 Days</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="float-xl-end">
                                                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="onReset('ce')">Reset</button>
                                                        <button class="btn btn-sm btn-primary mb-2" (click)="LoadAssets()">Filter</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="row mt-3">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="row" *ngIf="asstsList?.length!=0">
                                        <div class="col-xl-4 text-start">
                                            {{pageCCEEntrs}}
                                        </div>
                                        <div class="col-xl-4 text-center">
                                            <button class="btn btn-sm btn-success" (click)="exportAstLst()">
                                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                            </button>
                                        </div>
                                        <div class="col-xl-4 text-end">
                                            <pagination-controls previousLabel="" nextLabel="" id="pcCCEs"
                                                (pageChange)="setPage($event, 'ce')"></pagination-controls>
                                        </div>
                                    </div> <br />
                                    <div class="table_spacing table-responsive table-row-spacing">
                                        <div class="table_spacing table-responsive table-row-spacing">
                                            <table class="table table-sm table_spacing table-small-font p-0"
                                                style="min-width: 1100px;">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Item No.</th>
                                                        <th>Asset</th>
                                                        <th>Type</th>
                                                        <th>Working status</th>
                                                        <th class="text-center" style="width: 50%">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let a of asstsList | paginate: { itemsPerPage: pgSze, currentPage:
                                                            crntCCEPage, totalItems: ttlCCEItems, id: 'pcCCEs' }; let i=index">
                                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                                        <td>{{(pgSze*(crntCCEPage-1))+i+1}}</td>
                                                        <td *ngIf="isShowAstdtls"> 
                                                            <a>{{a.serialNumber}} - {{a.modelName}} ({{a.assetVendorName}})</a>
                                                        </td>
                                                        <td *ngIf="!isShowAstdtls">{{a.serialNumber}} - {{a.modelName}}
                                                            ({{a.assetVendorName}})</td>
                                                        <td>{{a.assetTypeName}}</td>
                                                        <td>{{a.assetStatus}}</td>
                                                        <td class="text-center">
                                                            <div *ngIf="a.assetTypeName=='Temperature Logger'">
                                                                <div *ngIf="a.deviceReadyStatus=='Device Ready'">
                                                                    <i class="fa fa-check-circle-o tickGreen" aria-hidden="true"></i>
                                                                </div>
                                                                <div *ngIf="a.deviceReadyStatus!='Device Ready'">
                                                                    <i class="fa fa-times-circle tickRed" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="a.assetTypeName!='Temperature Logger'">
                                                                <div class="d-flex bd-highlight flex-wrap">
                                                                    <div *ngFor="let sm of a.sensorMonitoringPoints; let j=index">
                                                                        <div *ngFor="let z of a.temperatureLogDataList; let k= index">
                                                                            <div *ngIf='a.temperatureLogDataList != null && a.sensorMonitoringPoints != null'>
                                                                                <div *ngIf='z.sensor == sm.sensor' [ngClass]="{'normal': (z.temperature < z.tempHigh) &&
                                                                                        (z.temperature > z.tempLow), 'highTemp': z.temperature > z.tempHigh, 'lowTemp':
                                                                                        z.temperature < z.tempLow, 'noClr': z.tempHigh == null &&  z.tempLow == null}" >
                                                                                    <div title="{{z.lastAlarmDate}}"
                                                                                            class="p-2 m-1 bd-highlight rounded box-outline-success tab_info_box">
                                                                                        <div class="float-start">
                                                                                            {{sm.monitoring_point}}: <span>{{sm.sensor}}</span>
                                                                                        </div>
                                                                                        <div class="float-end font-wt">
                                                                                            <span>{{z.temperature}}&deg;C</span>
                                                                                        </div>
                                                                                        <div class="float-end text-end">
                                                                                            <br />{{z.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="asstsList.length == 0">
                                                    <tr colspan="7">
                                                        <td class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> <br />
                                    <div class="row" *ngIf="asstsList?.length!=0">
                                        <div class="col-xl-6 text-start">
                                            {{pageCCEEntrs}}
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="text-end">
                                                <pagination-controls previousLabel="" nextLabel="" id="pcCCEs"
                                                    (pageChange)="setPage($event, 'ce')"></pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form> -->
                <!-- <evin-assets-list></evin-assets-list> -->
            </div>
            <div class="tab-pane fade" id="setup7-content" role="tabpanel" aria-labelledby="setup7-content-tab">
                <div class="card p-3 pt-2 border-0">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="card p-0 mb-3 mt-3">
                                <div class="card-header p-2">
                                    <div class="h6 mb-0">Location</div>
                                </div>
                                <div class="card-body p-2" *ngFor="let x of strPrfle">
                                    <div class="row">
                                        <div class="col-sm-4">Country:</div>
                                        <div class="col-sm-8">{{x?.country_name}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">State:</div>
                                        <div class="col-sm-8">{{x?.state_name}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">District:</div>
                                        <div class="col-sm-8">{{x?.district_name}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">Village/City:</div>
                                        <div class="col-sm-8">{{x?.city_name}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">Zip/Pin code:</div>
                                        <div class="col-sm-8">{{x?.pin}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="card h-25 mt-3" hidden>
                                <div class="card-body text-center p-3">
                                    No Image Available
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br /><br /><br />