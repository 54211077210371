<form [formGroup]="usrCreateForm" autocomplete="off">
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Identification</h6>
        </div>
        <div class="card-body p-4 pt-3 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <span class="small m-0">Please specify user's account information and role</span>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="user_id">Username<span class="text-danger">*</span></label>
                            <input type="text" placeholder="Username" class="form-control form-control-sm" minlength="4"
                                formControlName="user_id" (keyup)="onChngTBs('user_id', '8,48-57,65-90,97-122')" id="user_id"
                                onkeypress='return (event.charCode == 8 || (event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))'
                                [ngClass]="{ 'is-invalid': ((isSbmtd && fc.user_id.errors) || (isDup && dupMsg != '')) }"
                                maxlength="50" (change)="CheckDuplicateUN()">
                            <div *ngIf="isSbmtd && fc.user_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.user_id.errors.required">Username is required</div>
                                <div *ngIf="fc.user_id.errors.minlength || fc.user_id.errors.maxlength">
                                    Username length should be between 4 and 50 characters</div>
                            </div>
                            <div *ngIf="isDup && dupMsg != ''" class="invalid-feedback">
                                {{dupMsg}}
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="role_id">Role <span class="text-danger">*</span></label>
                            <select id="role_id" class="form-select form-select-sm" formControlName="role_id"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.role_id.errors }" (change)="onRoleChng($event)">
                                <option value="">-Select-</option>
                                <option *ngFor="let rl of rolesLst" [value]="rl.id">{{rl.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.role_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.role_id.errors.required">Role is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="dpassword">Password <span class="text-danger">*</span></label>
                            <!-- (keyup)="onChngPswd()" -->
                            <input type="password" placeholder="Password" class="form-control form-control-sm" autocomplete="off"
                                id="dpassword" formControlName="dpassword" autocomplete="new-password"
                                minlength="7" [ngClass]="{ 'is-invalid': isSbmtd && (fc.dpassword.errors || !isValidPswd) }"
                                onkeypress='return ((event.charCode >= 64 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)' maxlength="18">
                            <div class="show-hide pswdCSS">
                                <span [ngClass]="isPswdShow ? 'hide' : 'show'" (click)="onShwHde()"></span>
                            </div>
                            <div *ngIf="isSbmtd && fc.dpassword.errors" class="invalid-feedback">
                                <div *ngIf="fc.dpassword.errors.required">Password is required</div>
                                <div *ngIf="fc.dpassword.errors.minlength || fc.dpassword.errors.maxlength">Min. & Max. should be 7 & 18 char.s only and no spaces will be allowed</div>
                                <div *ngIf="fc.dpassword.errors.pattern">Enter atleast one number & one alphabet (in Upper or Lower)</div>
                                <!-- <div *ngIf="fc.dpassword.errors.minlength">Minimum length should be 7 char.s</div>
                                <div *ngIf="fc.dpassword.errors.maxlength">Maximum length should be 18 char.s</div> -->
                                <!-- <div *ngIf="fc.dpassword.errors.pattern">should contain atleast one specail character</div> -->
                            </div>
                            <!-- <div *ngIf="isSbmtd && !isValidPswd" class="invalid-feedback">
                                <div>Enter atleast one number & one alphabet (in Upper or Lower)</div>
                            </div> -->
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="cpwd">Confirm Password <span class="text-danger">*</span></label>
                            <input type="password" placeholder="Confirm Password" class="form-control form-control-sm"
                                id="cpwd" formControlName="cpwd" onkeypress='return ((event.charCode == 32)) ||
                                    (event.charCode >= 64 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) ||
                                    (event.charCode >= 48 && event.charCode <= 57)' [ngClass]="{ 'is-invalid': isSbmtd && fc.cpwd.errors }">
                            <div *ngIf="isSbmtd && fc.cpwd.errors" class="invalid-feedback">
                                <div *ngIf="fc.cpwd.errors.required">Confirm Password is required</div>
                                <div *ngIf="fc.cpwd.errors.mustMatch">Confirm Password must match Password</div>
                            </div>
                        </div>
                        <!-- <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Custom ID</label>
                            <input type="text" placeholder="Custom ID" class="form-control form-control-sm" id="">
                        </div> -->
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Tags <span class="text-danger">*</span></label>
                            <select class="form-select form-select-sm" formControlName="user_badge"
                                (change)="onItemSelect($event)" [ngClass]="{ 'is-invalid': isSbmtd && fc.user_badge.errors }">
                                <option value="">-Select-</option>
                                <option *ngFor="let usrBdg of userBadges" [value]="usrBdg.id">{{usrBdg.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.user_badge.errors" class="invalid-feedback">                                    
                                <div *ngIf="fc.user_badge.errors.required">Tags are required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Permissions</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="permissions">Permission</label>
                            <select id="permissions" class="form-select form-select-sm" formControlName="permissions" [ngModel]="permison">
                                <option value="default">Default</option>
                                <option value="view">View only</option>
                                <option value="asset view">Asset view only</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-9 mt-3">
                            <label for="textBox">Token expiry</label>
                            <div class="row">
                                <div class="col-sm-3">
                                    <input type="text" placeholder="" class="form-control form-control-sm"
                                        id="token_expiry_indays" formControlName="token_expiry_indays"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.token_expiry_indays.errors }">
                                    <div *ngIf="isSbmtd && fc.token_expiry_indays.errors" class="invalid-feedback">
                                        <div *ngIf="fc.token_expiry_indays.errors.minlength"></div>
                                        <div *ngIf="fc.token_expiry_indays.errors.maxlength"></div>
                                    </div>
                                </div>
                                <div class="col-sm-3">day(s)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Personal details</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify user's personal details</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="first_name">First name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="First name" class="form-control form-control-sm"
                                id="first_name" formControlName="first_name"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.first_name.errors }">
                            <div *ngIf="isSbmtd && fc.first_name.errors" class="invalid-feedback">
                                <div *ngIf="fc.first_name.errors.required">First name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="last_name">Last name</label>
                            <input type="text" placeholder="Last name" class="form-control form-control-sm"
                                id="last_name" formControlName="last_name">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="gender">Gender <span class="text-danger">*</span></label>
                            <select id="gender" class="form-select form-select-sm" formControlName="gender"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.gender.errors }">
                                <option value="">Select Gender</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.gender.errors" class="invalid-feedback">                                    
                                <div *ngIf="fc.gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="dob">Year of birth</label>
                            <div class="input-group">                               
                                <input type="text" autocomplete="off" placeholder="Year of birth" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpDOB="bsDatepicker" onpaste="return false"
                                    [maxDate]="maxdate"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY', minMode: 'year',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="dob" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpDOB.toggle()"
                                        [attr.aria-expanded]="dpDOB.isOpen">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpDOB.hide();clearBSDate('dob')">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Contact information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify user's Contact information</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Mobile number <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm" id="mobile"
                                    formControlName="mobile" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.mobile.errors }" minlength= "10" maxlength="10">
                                <div *ngIf="isSbmtd && fc.mobile.errors" class="invalid-feedback">                                    
                                    <div *ngIf="fc.mobile.errors.required">Mobile number is required</div>
                                    <div *ngIf="fc.mobile.errors.minlength || fc.mobile.errors.maxlength">
                                        Mobile number should be min. of 10 digits only
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="alt_number">Alternate mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Alternate mobile number" class="form-control form-control-sm"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' id="alt_number"
                                    formControlName="alt_number" [ngClass]="{ 'is-invalid': isSbmtd && fc.alt_number.errors }"
                                    minlength="10" maxlength="10">
                                <div *ngIf="isSbmtd && fc.alt_number.errors" class="invalid-feedback">
                                    <div *ngIf="fc.alt_number.errors.minlength || fc.alt_number.errors.maxlength">
                                        Alternate mobile number should be min. of 10 digits only
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="email">Email <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Email" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.email.errors }" id="email"
                                formControlName="email" (change)="onChkEmail($event)">
                            <div *ngIf="isSbmtd && fc.email.errors" class="invalid-feedback">
                                <div *ngIf="fc.email.errors.required">Email is required</div>
                                <div *ngIf="fc.email.errors.email">Email should be in proper format</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Location</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify location information for the Facility</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="country_id">Country <span class="text-danger">*</span></label>
                            <select id="country_id" class="form-select form-select-sm" formControlName="country_id"
                                (change)="onNestedSlct($event, 'country')" [attr.disabled]="true"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.country_id.errors }">
                                <option value="">-Select-</option>
                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.country_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.country_id.errors.required">Country is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">State <span class="text-danger">*</span></label>
                            <select id="state_id" class="form-select form-select-sm" formControlName="state_id"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.state_id.errors }"
                                (change)="onNestedSlct($event, 'state')">
                                <option value="null">All</option>
                                <option *ngFor="let s of stsLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.state_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.state_id.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">District <span *ngIf="isDistMndtry" class="text-danger">*</span></label>
                            <select id="district_id" class="form-select form-select-sm" formControlName="district_id"
                                [ngClass]="{ 'is-invalid': isSbmtd && isDistMndtry && fc.district_id.errors }"
                                (change)="onNestedSlct($event, 'district')">
                                <option value="null">All</option>
                                <option *ngFor="let s of dstrtLst" [value]="s.districtid">{{s.districtname}}</option>
                            </select>
                            <div *ngIf="isSbmtd && isDistMndtry && fc.district_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.district_id.errors.required">District is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Taluk/Block</label>
                            <select id="block_id" class="form-select form-select-sm" formControlName="block_id">
                                <option value="null"> All</option>
                                <option *ngFor="let s of blkLst" [value]="s.blockid">{{s.blockname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="city_id">Village/City</label>
                            <input type="text" placeholder="Village/City" class="form-control form-control-sm"
                                id="city_id" formControlName="city_id">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="address">Street address</label>
                            <input type="text" placeholder="Street address" class="form-control form-control-sm"
                                id="address" formControlName="address">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="pin">Zip/PIN code</label>
                            <input type="text" placeholder="Zip/PIN code" class="form-control form-control-sm" id="pin"
                                formControlName="pin" [ngClass]="{ 'is-invalid': isSbmtd && fc.pin.errors }" minlength="6" maxlength="6"
                                 onkeypress='return (event.charCode >=48 && event.charCode <=57)'>
                            <div *ngIf="isSbmtd && fc.pin.errors" class="invalid-feedback">
                                <div *ngIf="fc.pin.errors.minlength || fc.pin.errors.maxlength">Min. & Max. should be 6 char.s only</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="language_id">Language</label>
                            <select id="language_id" class="form-select form-select-sm" formControlName="language_id" >
                                <!-- [ngClass]="{ 'is-invalid': isSbmtd && fc.language_id.errors }" -->
                                <option value="">Select Language</option>
                                <option *ngFor="let ln of LngLst" value="{{ln.id}}">{{ln.name}}</option>
                            </select>
                            <!-- <div *ngIf="isSbmtd && fc.language_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.language_id.errors.required">Language is required</div>
                            </div> -->
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="time_zone_id">Timezone <span class="text-danger">*</span></label>
                            <select id="time_zone_id" class="form-select form-select-sm" formControlName="time_zone_id"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.time_zone_id.errors }" [disabled]="isSnglTZ">
                                <option value="" *ngIf="!isSnglTZ">Select Timezone</option>
                                <option *ngFor="let tz of tmZoneLst" value="{{tz.id}}">{{tz.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.time_zone_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.time_zone_id.errors.required">Timezone is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Device details</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="mobile_brand">Mobile phone brand</label>
                            <input type="text" placeholder="Mobile phone brand" class="form-control form-control-sm"
                                id="mobile_brand" formControlName="mobile_brand" maxlength="50">
                                <!-- [ngClass]="{ 'is-invalid': isSbmtd && fc.mobile_brand.errors }" -->
                            <!-- <div *ngIf="isSbmtd && fc.mobile_brand.errors" class="invalid-feedback">
                                <div *ngIf="fc.mobile_brand.errors.required">Mobile phone brand is required</div>
                                <div *ngIf="isSbmtd && fc.mobile_brand.errors" class="invalid-feedback">
                                    <div *ngIf="fc.mobile_brand.errors.maxlength"></div>
                                </div>
                            </div> -->
                            <p class="small pb-0 mb-0">(e.g.Nokia)</p>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="mobile_model">Mobile phone model</label>
                            <input type="text" placeholder="Mobile phone model" class="form-control form-control-sm"
                                id="mobile_model" formControlName="mobile_model" maxlength="50">
                                <!-- [ngClass]="{ 'is-invalid': isSbmtd && fc.mobile_model.errors }" -->
                            <!-- <div *ngIf="isSbmtd && fc.mobile_model.errors" class="invalid-feedback">
                                <div *ngIf="fc.mobile_model.errors.required">Mobile phone model is required</div>
                                <div *ngIf="isSbmtd && fc.mobile_model.errors" class="invalid-feedback">
                                    <div *ngIf="fc.mobile_model.errors.maxlength"></div>
                                </div>
                            </div> -->
                            <p class="small pb-0 mb-0">(e.g.2700, Xpressmusic)</p>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="imei">IMEI number</label>
                            <input type="text" placeholder="IMEI number" class="form-control form-control-sm" id="imei"
                                formControlName="imei" [ngClass]="{ 'is-invalid': isSbmtd && fc.imei.errors }"
                                maxlength="50" onkeypress='return (event.charCode >=48 && event.charCode <=57)'>
                            <p class="small pb-0 mb-0">(e.g.Airtel)</p>
                            <div *ngIf="isSbmtd && fc.imei.errors" class="invalid-feedback">
                                <div *ngIf="fc.imei.errors.maxlength"></div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="msp">SIM provider</label>
                            <input type="text" placeholder="SIM provider" class="form-control form-control-sm" id="msp"
                                formControlName="msp" [ngClass]="{ 'is-invalid': isSbmtd && fc.msp.errors }"
                                maxlength="50">
                            <p class="small pb-0 mb-0">(e.g.Airtel)</p>
                            <div *ngIf="isSbmtd && fc.msp.errors" class="invalid-feedback">
                                <div *ngIf="fc.msp.errors.maxlength"></div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="sim">SIM ID</label>
                            <input type="text" placeholder="SIM ID" class="form-control form-control-sm" id="sim"
                            formControlName="sim" [ngClass]="{ 'is-invalid': isSbmtd && fc.sim.errors }" maxlength="50">
                            <div *ngIf="isSbmtd && fc.sim.errors" class="invalid-feedback">
                                <div *ngIf="fc.sim.errors.maxlength"></div>
                            </div>
                        </div>
                        <div class="form-group col-xl-9 mt-3">
                            <label for="textBox">Remote login</label>
                            <div class="form-check ms-2">
                                <input class="form-check-input" type="radio" name="remote_login" id="remote_login"
                                    value="Default as Domain" formControlName="remote_login">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Use the default for this domain
                                </label>
                                <p class="small pb-0 mb-0">Following options override the domain's default value</p>
                            </div>
                            <div class="form-check ms-2">
                                <input class="form-check-input" type="radio" name="remote_login" id="remote_login"
                                    value="Server Login" formControlName="remote_login">
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Make 'Login' connect to server each time (reconnect/refresh option will not appear
                                    on mobile)
                                </label>
                            </div>
                            <div class="form-check ms-2">
                                <input class="form-check-input" type="radio" name="remote_login" id="remote_login"
                                    value="Local Login" formControlName="remote_login">
                                <label class="form-check-label" for="flexRadioDefault3">
                                    Allow local login (reconnect/refresh option will appear on mobile)
                                </label>
                            </div>
                        </div>
                        <!-- <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Stores app GUI theme</label>
                            <div class="form-check ms-2">
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                    id="flexRadioDefault1">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Use the default for this domain
                                </label>
                                <p class="small pb-0 mb-0">Following options override the domain's default value</p>
                            </div>
                            <div class="form-check ms-2">
                                <input class="form-check-input" type="radio" name="flexRadioDefault"
                                    id="flexRadioDefault2" checked>
                                <label class="form-check-label" for="flexRadioDefault2">
                                    Select theme for the user
                                </label>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-primary me-2 mb-2" (click)="isSbmtd=true;createUser()">Save</button>
                        <button class="btn btn-sm btn-light mb-2" (click)="isSbmtd=false;cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>