import { Component, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import * as FusionCharts from 'fusioncharts';

import { GlobalService } from 'src/app/Services/global.service';
import { ApiCalls, DateFrmts, TimeFrmts } from 'src/app/Enums/enums';
import { environment } from 'src/environments/environment.prod';

declare var $: any;

@Component({
  selector: 'evin-base',
  template: '',
  styles: []
})

export class BaseComponent implements OnInit {

  cstmDt: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success'] }];
  cstmDtDef: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success'] }];
  cstmDt2: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success']}];
  cstmDtDef2: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success'] }];
  cstmDt3: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success']}];
  cstmDtDef3: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success'] }];
  cstmDt4: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success']}];
  cstmDtDef4: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success'] }];

  result = new RspnsAPI(); clAPI = ApiCalls; dtF = DateFrmts; tmF = TimeFrmts; usrStrLen = 0; usrLat: any; usrLon: any;
  lstPrdCty = [{ val: 'M', name: 'Monthly' }, { val: 'W', name: 'Weekly' }, { val: 'D', name: 'Daily' }]; dt2Day = new Date();
  lstPrdCtys = [{ val: 'm', name: 'Monthly' },  { val: 'w', name: 'Weekly' }, { val: 'd', name: 'Daily' }]; isSA = false;
  lstPrdCtyss = [{ val: '1', name: 'Total' },  { val: '2', name: 'AVG Monthly' }, { val: '3', name: 'AVG weekly' }];chsnDmnId: number = 0;
  chsnDmnName = ''; usrLgnDtls: any = {}; lstATs = [{ id: 200, name: 'Zero Stock' }, { id: 201, name: '< Min' }, { id: 202, name: '> Max' }];
  lstMnths = [{ val: '01', name: 'January', days: '31' }, { val: '02', name: 'February', days: '28' }, { val: '03', name: 'March', days: '31' },
    { val: '04', name: 'April', days: '30' }, { val: '05', name: 'May', days: '31' }, { val: '06', name: 'June', days: '30' },
    { val: '07', name: 'July', days: '31' }, { val: '08', name: 'August', days: '31' }, { val: '09', name: 'September', days: '30' },
    { val: '10', name: 'October', days: '31' }, { val: '11', name: 'November', days: '30' }, { val: '12', name: 'December', days: '31' }];
  mnths = [{ val: '01', name: 'Jan', days: '31' }, { val: '02', name: 'Feb', days: '28' }, { val: '03', name: 'Mar', days: '31' },
    { val: '04', name: 'Apr', days: '30' }, { val: '05', name: 'May', days: '31' }, { val: '06', name: 'Jun', days: '30' },
    { val: '07', name: 'Jul', days: '31' }, { val: '08', name: 'Aug', days: '31' }, { val: '09', name: 'Sep', days: '30' },
    { val: '10', name: 'Oct', days: '31' }, { val: '11', name: 'Nov', days: '30' }, { val: '12', name: 'Dec', days: '31' }];
  lstFsnIndSNs = [{ id: '001', name: 'Andaman and Nicobar Islands', shortLabel: 'AN' }, { id: '002', name: 'Andhra Pradesh', shortLabel: 'AP' },
    { id: '003', name: 'Arunachal Pradesh', shortLabel: 'AR' }, { id: '004', name: 'Assam', shortLabel: 'AS' }, { id: '005', name: 'Bihar',
      shortLabel: 'BI' }, { id: '006', name: 'Chandigarh', shortLabel: 'CH' }, { id: '007', name: 'Chhattisgarh', shortLabel: 'CA' }, { id: '008',
      name: 'Dadra and Nagar Haveli', shortLabel: 'DN' }, { id: '009', name: 'Daman and Diu', shortLabel: 'DD' }, { id: '010', name: 'Delhi',
      shortLabel: 'DE' }, { id: '011', name: 'Goa', shortLabel: 'GO' }, { id: '012', name: 'Gujarat', shortLabel: 'GU' }, { id: '013',
      name: 'Haryana', shortLabel: 'HA' }, { id: '014', name: 'Himachal Pradesh', shortLabel: 'HP' }, { id: '015', name: 'Jammu and Kashmir',
      shortLabel: 'JK' }, { id: '016', name: 'Jharkhand', shortLabel: 'JH' }, { id: '017', name: 'Karnataka', shortLabel: 'KA' }, { id: '018',
      name: 'Kerala', shortLabel: 'KE' }, { id: '019', name: 'Lakshadweep', shortLabel: 'LA' }, { id: '020', name: 'Madhya Pradesh',
      shortLabel: 'MP' }, { id: '021', name: 'Maharashtra', shortLabel: 'MA' }, { id: '022', name: 'Manipur', shortLabel: 'MN' }, { id: '023',
      name: 'Meghalaya', shortLabel: 'ME' }, { id: '024', name: 'Mizoram', shortLabel: 'MI' }, { id: '025', name: 'Nagaland', shortLabel: 'NA' },
    { id: '026', name: 'Odisha', shortLabel: 'OR' }, { id: '027', name: 'Puducherry', shortLabel: 'PO' }, { id: '028', name: 'Punjab',
      shortLabel: 'PU' }, { id: '029', name: 'Rajasthan', shortLabel: 'RA' }, { id: '030', name: 'Sikkim', shortLabel: 'SI' }, { id: '031',
      name: 'Tamil Nadu', shortLabel: 'TN' }, { id: '032', name: 'Tripura', shortLabel: 'TR' }, { id: '033', name: 'Uttar Pradesh',
      shortLabel: 'UP' }, { id: '034', name: 'Uttarakhand', shortLabel: 'UT' }, { id: '035', name: 'West Bengal', shortLabel: 'WB' },
    { id: '036', name: 'Telangana', shortLabel: 'TL' }]; wkBkDt = new Date(new Date().setDate((new Date()).getDate() - 6));
  lstCPOs = [{id: 1, name: 'MASTER STATUS' }, {id: 2, name: 'MASTER REASON' }, {id: 3, name: 'CATEGORY' }, {id: 4, name: 'SYSTEM CONFIGURATIONS' },
    {id: 5, name: 'ROLE PERMISSION CONFIGURATIONS' }, {id: 6, name: 'ASSET CONFIGURATIONS' }, {id: 7, name: 'SENSOR CONFIGURATIONS' }];
 lstTempStatus: any[] = [{ 'name': 'Low', 'id': 1 }, { 'name': 'High', 'id': 2 }, { 'name': 'Normal', 'id': 3 }, { 'name': 'Unknown', 'id': 4 }]; 
 lsrPrd: any[] = [{ 'name': 'Last 3 Months', 'id': 1 }, { 'name': 'Last 6 Months', 'id': 2 }, { 'name': 'Last 9 Months', 'id': 3 },
   { 'name': 'Last 1 Year', 'id': 4 }];
    maxFrmDate: any = '';  maxToDate: any = '';
  dayTxt = environment.dayMonth.Day; mnthTxt = environment.dayMonth.Month; isNtnlAdmn = false;
  slctDmnDetails: any = JSON.parse(<string>localStorage.getItem('slctdDmnDtls')); slctDmnName: any = localStorage.getItem('slctdDmnName');
  mstrDta: any = JSON.parse(<string>localStorage.getItem('lgnMstrsData')); lgnUsrDtls = JSON.parse(<string>localStorage.getItem('lgnUsrDtls'));
  
  constructor(public glblSvc: GlobalService) {
    glblSvc.sbjctChsnDmnId.subscribe(s => this.chsnDmnId = s), glblSvc.sbjctChsnDmnName.subscribe(s => this.chsnDmnName = s),
      glblSvc.sbjctLgnUsrDtls.subscribe(s => this.usrLgnDtls = s);     
      if ((Object.keys(this.usrLgnDtls).length == 0 || localStorage.getItem('lgnUsrDtls') == null) && !window.location.href.includes('frgt-pswd')
      && !window.location.href.includes('rest-pswd'))
      document.getElementById('btnLgnPg')?.click();
  }

  ngOnInit(): void {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false }), this.glblSvc.setDtFrmt((new Date()), this.dtF.dmy);
    if (this.slctDmnDetails == null) {
      let dmnIntrval = setInterval(() => {
        this.slctDmnDetails = JSON.parse(<string>localStorage.getItem('slctdDmnDtls'));
        if (this.slctDmnDetails != null)
          clearInterval(dmnIntrval);
      }, 500)
    }
  }
  
  ChkNtnlUsr(): boolean {
    return (this.lgnUsrDtls != null && this.lgnUsrDtls != undefined) ? ((this.lgnUsrDtls.roleId == 3 || this.lgnUsrDtls.roleId == 6) && this.lgnUsrDtls.pranthName == 'India') : false;
  }

  ChkNtnlUsrByPrnthId(): boolean {
    return localStorage.getItem('slctdDmnId') != null ? (localStorage.getItem('slctdDmnId') === '1343774') : false;
  }

  async GetLatLon() {
    await navigator.geolocation.getCurrentPosition((position) => { [this.usrLat, this.usrLon] = 
      [this.usrLat = position.coords.latitude, this.usrLon = position.coords.longitude];
      console.log(`lat=${this.usrLat},long=${this.usrLon}`);
    });
  }

  async FetchData(apiType: string, apiPath: string, apiMthd: string, payLoad: any, svcTagIndx = 0, isSpnr = true, prntPathTagIndx: number = 0, isEvinGW: boolean = true, isLcl = false) {
    if (apiType != '') {
      this.result = new RspnsAPI(), this.glblSvc.sbjctSpnr.next(isSpnr);
      switch (apiType) {
        case this.clAPI.get:
          await this.glblSvc.getAPI(apiPath, apiMthd, svcTagIndx, prntPathTagIndx, isEvinGW, isLcl).toPromise().catch((err: Response) => {
            let bug: any = err;
            this.glblSvc.sbjctSpnr.next(false);
            if (bug.error?.status == 401)
              setTimeout(() => { console.log(bug.error); }, 500), sessionStorage.setItem('usrURL', window.location.href), this.onSignOut(), this.glblSvc.onTstr('w', environment.ssnExpryMsg, bug.error?.error);
            else {
              if (bug.error?.status == 'EXPECTATION_FAILED' || bug.error?.status == 'INTERNAL_SERVER_ERROR' || bug.error == null) {
                this.glblSvc.onTstr('e', bug.error?.message);
                if (bug.error?.message == 'Invalid captcha')
                  this.onReGenCaptcha();
              }
              console.log('bug-error=', bug.error?.message ?? environment.bugMsg); // this.glblSvc.onTstr('e', bug.error?.message ?? environment.bugMsg);
            }
          }).then((res: Response) => {
            let rslt: any = res;
            if (isSpnr)
              setTimeout(() => { this.glblSvc.sbjctSpnr.next(false) }, 1000);
            if (rslt) {
              if ((rslt.status == "OK") || ((rslt.status == null || rslt.status != '') && rslt.message != ''))
                this.result = rslt;
              else
                this.result = new RspnsAPI();
            }
            else
              this.result = new RspnsAPI();
          });
          break;
        case this.clAPI.post:
          await this.glblSvc.postAPI(apiPath, apiMthd, payLoad, svcTagIndx, prntPathTagIndx, isLcl).toPromise().catch((err: Response) => {
            let bug: any = err;
            this.glblSvc.sbjctSpnr.next(false);
            if (bug.error?.status == 401)
              setTimeout(() => { console.log(bug.error); }, 500), sessionStorage.setItem('usrURL', window.location.href),
                this.onSignOut(), this.glblSvc.onTstr('w', environment.ssnExpryMsg, bug.error?.error);
            else {
              if (bug.error?.status == 'EXPECTATION_FAILED' || bug.error?.status == 'INTERNAL_SERVER_ERROR' || bug.error == null)
                this.glblSvc.onTstr('e', bug.error?.message);
              console.log('bug-error=', bug.error?.message ?? environment.bugMsg); // this.glblSvc.onTstr('e', bug.error?.message ?? environment.bugMsg);
            }
          }).then((res: Response) => {
            let rslt: any = res;
            if (isSpnr)
              setTimeout(() => { this.glblSvc.sbjctSpnr.next(false) }, 1000);
            if (rslt) {
              if ((rslt.status == "OK") || ((rslt.status == null || rslt.status != '') && rslt.message != ''))
                this.result = rslt;
              else
                this.result = new RspnsAPI();
            }
            else
              this.result = new RspnsAPI();
          });
          break;
        case this.clAPI.del:
          await this.glblSvc.deleteAPI(apiPath, apiMthd, svcTagIndx, prntPathTagIndx, isLcl).toPromise().catch((err: Response) => {
            let bug: any = err;
            this.glblSvc.sbjctSpnr.next(false);
            if (bug.error?.status == 401)
              setTimeout(() => { console.log(bug.error); }, 500), sessionStorage.setItem('usrURL', window.location.href), this.onSignOut(), this.glblSvc.onTstr('w', environment.ssnExpryMsg, bug.error?.error);
            else {
              if (bug.error?.status == 'EXPECTATION_FAILED' || bug.error?.status == 'INTERNAL_SERVER_ERROR' || bug.error == null)
                this.glblSvc.onTstr('e', bug.error?.message);
              console.log('bug-error=', bug.error?.message ?? environment.bugMsg); // this.glblSvc.onTstr('e', bug.error?.message ?? environment.bugMsg);
            }
          }).then((res: Response) => {
            let rslt: any = res;
            if (isSpnr)
              setTimeout(() => { this.glblSvc.sbjctSpnr.next(false) }, 1000);
            if (rslt) {
              if ((rslt.status == "OK") || ((rslt.status == null || rslt.status != '') && rslt.message != ''))
                this.result = rslt;
              else
                this.result = new RspnsAPI();
            }
            else
              this.result = new RspnsAPI();
          });
          break;
        default:
          break;
      }
    }
  }

  async onFormData(apiPath: string, apiMthd: string,  frmData: any, svcTagIndx = 0, isSpnr = true,  prntPathTagIndx: number = 0, isLcl = false) {
    this.glblSvc.sbjctSpnr.next(true);
    await this.glblSvc.FormDataSave(apiPath, apiMthd, frmData, svcTagIndx, prntPathTagIndx, isLcl).toPromise().catch((err: Response) => {
        let bug: any = err;
        this.glblSvc.sbjctSpnr.next(false);
        if (bug.error?.status == 401)
          setTimeout(() => { console.log(bug.error); }, 500), sessionStorage.setItem('usrURL', window.location.href), this.onSignOut(), this.glblSvc.onTstr('w', environment.ssnExpryMsg, bug.error?.error);
        else {
          if (bug.error?.status == 'EXPECTATION_FAILED' || bug.error?.status == 'INTERNAL_SERVER_ERROR' || bug.error == null)
            this.glblSvc.onTstr('e', bug.error?.message);
          console.log('bug-error=', bug.error?.message ?? environment.bugMsg); // this.glblSvc.onTstr('e', bug.error?.message ?? environment.bugMsg);
        }
      }).then((res: Response) => {
        let rslt: any = res;
        if (isSpnr)
          setTimeout(() => { this.glblSvc.sbjctSpnr.next(false) }, 1000);
        if (rslt) {
          if ((rslt.status == "OK") || ((rslt.status == null || rslt.status != '') && rslt.message != ''))
            this.result = rslt;
          else
            this.result = new RspnsAPI();
        }
        else
          this.result = new RspnsAPI();
    });
  }

  onReGenCaptcha() {
    let cptchTxt = '';
    this.glblSvc.sbjctCptcha.subscribe(s => { if (s) cptchTxt = s.captchaId; });
    if (cptchTxt != '') {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.captcha, environment.apiMthds.reGenCptch + cptchTxt, null, 3).then(() => {
        let resCap = this.result;
        if (this.result) {
          if (this.result.status == 'OK')
            this.glblSvc.sbjctCptcha.next(this.result.data);
          else
            this.glblSvc.onTstr('i', this.result.message)
        }
        else
          this.glblSvc.onTstr('e', resCap.message)
      });
    }
  }

  setElmntFocus(elmntName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elmntName)));
    if (el)
      el.value = '', el.focus();
  }

  ShowHidePwd(elmntName: string, isShow: boolean) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elmntName)));
    el.type = isShow ? 'text' : 'password';
  }

  checkFormValidations(formObj: any) {
    Object.keys(formObj.controls).forEach(key => {
      const controlErrors: ValidationErrors = formObj.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  checkFormArrayValidations(formObj: any) {
    Object.keys(formObj.controls).forEach((e: any) => {
      Object.keys(formObj.controls[e].controls).forEach((key: any) => {
        const controlErrors: ValidationErrors = formObj.controls[e].controls[key].errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
    });
  }

  openFileNewWndw(filePath: string) {
    window.open(filePath, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');
  }

  onValCopyMW(val: any) {
    if (window.orientation == undefined) {
      let selBox = document.createElement('textarea'); selBox.style.position = 'fixed', selBox.style.left = '0', selBox.style.top = '0',
        selBox.style.opacity = '0', selBox.value = val, selBox.focus(), document.body.appendChild(selBox), selBox.select(),
        document.execCommand('copy'), document.body.removeChild(selBox), this.glblSvc.onTstr('s', 'Copied to clipboard.!');
    }
    else {
      let link = document.createElement('a');
      link.setAttribute('href', `tel:${val}`), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
        link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
    }
  }

  onChkTBFrmt(frmt: any, val: any): boolean {
    let res = false;
    if (val != '' && val != null && val != undefined) {
      if (frmt) {
        if (frmt == 'a') { // alphabets
          if ((!(val.match("[a-zA-Z]+"))) || (val.match(/\d+$/)) || (val.match(`/[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/`)))
            res = true;
        }
        else if (frmt == 'n') { // numerics
          if (!(val.match(/\d+$/)))
            res = true;
        }
        else if (frmt == 'an') { // alphanumerics
          if (val.match(/[^a-zA-Z0-9 ]/g))
            res = true;
        }
      }
    }
    return res;
  }

  onChkMinMaxTB(val: any, minLen: number, maxLen: number): boolean {
    let res = false;
    if (val != '' && val != null && val != undefined) {
      if (val.length < minLen) {
        return true;
      }
    }
    return res;
  }

  OpenFileInNewTab(filePath: string) {
    let link = document.createElement("a");
    link.setAttribute('href', filePath), link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'),
      link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
  }

  DownloadFileCSV(data: any, fileName: any) {
    let csvData = this.ConvertToCSV(data,Object.keys(data[0])), blob = new Blob(['\ufeff' + csvData],
      { type: 'text/csv;charset=utf-8;' }), dwldLink = document.createElement("a"), url = URL.createObjectURL(blob),
      isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1,
      finalName = fileName + '_' + this.glblSvc.setDtFrmt(new Date(), this.dtF._ymd, this.tmF._hms, false);
    if (isSafariBrowser) //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    dwldLink.setAttribute("href", url), dwldLink.setAttribute("download", finalName + ".csv"), dwldLink.style.visibility = "hidden",
      document.body.appendChild(dwldLink), dwldLink.click(), document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray, str = '', row = 'S.No,';
    for (let index in headerList)
      row += headerList[index] + ',';
    row = row.slice(0, -1), str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  sortAryLst(list: any, key: any) {
    list.sort((a: any, b: any) => (a[key] === b[key]) ? 0 : (a[key] > b[key]) ? 1 : -1)
  }

  GenUnqText(): string {
    let res = '';
    for (var i = 0; i < environment.rndmTxtLen; i++)
      res += environment.rndmTxtChars.charAt(Math.floor(Math.random() * environment.rndmTxtChars.length));
    return res;
  }

  select2ShowHide() {
    $(".js-example-basic-hide-search").select2({ minimumResultsForSearch: Infinity });
  }

  getClrCode(codeId: any) {
    return (codeId == 200 ? environment.clrsMinMax.stkOuts : (codeId == 201 ? environment.clrsMinMax.lessMin : (codeId == 202 ? environment.clrsMinMax.moreMax : (codeId == 0 ? environment.clrsMinMax.normal : null))));
  }

  CheckMonthDiff(dateFrom: Date, dateTo: Date, isAddMnth: boolean = true) {
    return ((dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))) + (isAddMnth ? 1 : 0));
  }

  CheckWeeksDiff(dateFrom: any, dateTo: any, isDelWeek: boolean = true) {
    return Math.round((dateTo - dateFrom) / (7 * 24 * 60 * 60 * 1000)) + (isDelWeek ? 1 : 0);
  }

  CheckDaysDiff(dateFrom: any, dateTo: any, isAddMnth: boolean = true) {
    // let diffYear = new Date(dateTo - dateFrom).getFullYear();
    // return diffYear == 1970 ? -1 : Math.round(Math.abs((dateTo - dateFrom) / (24 * 60 * 60 * 1000)));
    return Math.round(Math.abs((dateTo - dateFrom) / (24 * 60 * 60 * 1000))) + (isAddMnth ? 1 : 0);
  }

  GetReqDate4mCrntWeek(dayIndx: number = 0) {
    let curr = new Date, reqDay = (curr.getDate() - curr.getDay()) + dayIndx;
    return new Date(curr.setDate(reqDay));
  }

  openRprtsRouteMdl() {
    $('#rprtsRtngMdl')?.click();
  }

  GetMonthName(mnthNum: number) {
    return this.lstMnths.filter(f => +f.val == mnthNum)[0]?.name;
  }

  GetMonthYear(val: any, setShrt: boolean = false) {
    if (`${val}`.includes(' '))
      return val; // = `${val}`.replace(' ', '/');
    if (`${val}`.includes('/')) {
      if (setShrt)
        return `${this.lstMnths.filter(f => +f.val == +val.split('/')[0])[0]?.name.substring(0, 3)} ${val.split('/')[1]}`;
      else
        return `${this.lstMnths.filter(f => +f.val == +val.split('/')[0])[0]?.name} ${val.split('/')[1]}`;
    }
    // else if (`${val}`.includes(' ')) {
    //   if (setShrt)
    //     return `${this.lstMnths.filter(f => +f.val == +val.split(' ')[0])[0]?.name.substring(0, 3)} ${val.split(' ')[1]}`;
    //   else
    //     return `${this.lstMnths.filter(f => +f.val == +val.split(' ')[0])[0]?.name} ${val.split(' ')[1]}`;
    // }
    else
      return `${this.lstMnths.filter(f => +f.val == +val)[0]?.name}`;
  }

  GetMonthDates(mnthNum: number, year: number = (new Date).getFullYear()) {
    let dt = new Date(), maxDt = mnthNum === dt.getMonth() + 1 && year === dt.getFullYear(),
      getDt = `${(dt.getDate()).toString().length==1?'0':''}${dt.getDate()}`;
    var mnth = this.lstMnths.filter(f => +f.val === mnthNum)[0], mnthDays = mnthNum === 2 && year % 4 === 0 ? '29' : mnth.days,
      fD = `${year}-${mnth.val}-01`, eD = `${year}-${mnth.val}-${maxDt ? getDt : mnthDays}`;
    return `${fD}...${eD}`;
  }

  GetWeekDates(val: Date) {
    let fD = this.glblSvc.setDtFrmt(val, this.dtF.ymd), endDate = this.glblSvc.setDtFrmt(this.GetReqDate4mCrntWeek(1), this.dtF.ymd) ===
      this.glblSvc.setDtFrmt(val, this.dtF.ymd) ? new Date() : val.setDate(val.getDate() + 6), eD = this.glblSvc.setDtFrmt(endDate, this.dtF.ymd);
    return `${fD}...${eD}`;
  }

  dateConverterTo(dtVal: any) {
    let date = new Date(dtVal), dd: any = date.getDate(), mm: any = date.getMonth() + 1;
    if (mm <= 9)
      mm = '0' + mm;
    if (dd <= 9)
      dd = '0' + dd;
    let yyyy = date.getFullYear(), vaidDate = yyyy + "-" + mm + "-" + dd;
    return vaidDate;
  }

  MustMatch(ctrlName: string, matchVal: string) {
    return (formGroup: FormGroup) => { const control = formGroup.controls[ctrlName], matchingControl = formGroup.controls[matchVal];
      if (matchingControl.errors && !matchingControl.errors.mustMatch)
        return;
      if (control.value !== matchingControl.value)
        matchingControl.setErrors({ mustMatch: true });
      else
        matchingControl.setErrors(null);
    }
  }

  GetPageSizeTxt(crntPage: any, pageSize: any, totalItems: any) {
    return `Showing ${((crntPage * pageSize) - (pageSize - 1))}-${((crntPage * pageSize) < (totalItems)) ? (crntPage * pageSize) : (totalItems)} of ${totalItems} entries`;
  }

  GetDtesMinMax( fDte: any, tDte: any) {
   let crntDte = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd), frmDte = this.glblSvc.setDtFrmt(fDte, this.dtF.ymd_),
    mnF = frmDte.split("-"), frmDateDay = mnF[0] + '-' + mnF[1] + '-' + '01', toDte = this.glblSvc.setDtFrmt(tDte, this.dtF.ymd_),
    mnTo = toDte.split("-"), mnth = this.lstMnths.filter(m => m.val == mnTo[1]), toDateDay = mnTo[0] + '-' + mnTo[1] + '-' + mnth[0]?.days;
    ( crntDte > frmDateDay) ? ( this.maxFrmDate = crntDte ) : ( this.maxFrmDate = frmDateDay ), ( crntDte < toDateDay) ? 
    ( this.maxToDate = toDateDay ) : ( this.maxToDate = crntDte );
  }

  onSignOut() {
    let payLoad: any = {};
    if (this.usrLgnDtls) {
      if (this.usrLgnDtls.userId && this.usrLgnDtls.token) {
        payLoad = { userId: +this.usrLgnDtls.userId, token: this.usrLgnDtls.token }, this.glblSvc.sbjctSpnr.next(true),
          this.glblSvc.logoutAPI(environment.apiPaths.loginout, environment.apiMthds.logout, payLoad).subscribe((s: any) => {
            if (s) {
            // if (s.data === 'Success' && s.status == 'OK')
              document.getElementById('btnLgnPg')?.click();
          }
          this.glblSvc.sbjctSpnr.next(false);
        });
      }
    }
    else 
    document.getElementById('btnLgnPg')?.click();
  }

  BindChart(xXName: string, yXName: string, lstLbls: any[], lstData: any[], divId: string, type: any = 'mscombi2d', srsName = 'Issues') {
    FusionCharts.ready(function () {
      var salesAnlysisChart = new FusionCharts({
        type: type,
        renderAt: divId,
        width: '800',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "subCaption": "",
            "xAxisname": yXName,
            "yAxisName": xXName,
            "numberPrefix": "",
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "usePlotGradientColor": "0",
            "showAlternateHGridColor": "0",
            "showCanvasBorder": '0',
            "showBorder": "0",
            "showPlotBorder": "0",
            // "numberSuffix": "M",
            "bgColor": "#FFFFFF",
            "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "bar",
            "theme": "fusion"
          },
          "categories": [{
            "category": lstLbls
          }],
          "dataset": [{
            "seriesName": srsName,
            "showValues": "1",
            "data": lstData
          }],
          // "dataset": [{
          //     "seriesName": "Issues",
          //     "showValues": "1",
          //     "data": lstXI
          //   },
          //   {
          //     "seriesName": "Returns",
          //     "renderAs": "mscombi2d",
          //     "data": []
          //   },
          //   {
          //     "seriesName": "Net Consumption",
          //     "renderAs": "line",
          //     "data": lstXN
          //   }
          // ]
        }
      }).render();
    });
  }

  LoadChart2(lstXL: any[], srsName = 'Issues') {
    FusionCharts.ready(function () {
      var salesAnlysisChart = new FusionCharts({
        type: 'mscombi2d',
        renderAt: 'fc-multi2',
        width: '800',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "subCaption": "",
            "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
            "yAxisName": "All issues/net utilization (All)",
            "numberPrefix": "",
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "usePlotGradientColor": "0",
            "showAlternateHGridColor": "0",
            "showCanvasBorder": '0',
            "showBorder": "0",
            "showPlotBorder": "0",
            // "numberSuffix": "M",
            "bgColor": "#FFFFFF",
            "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "bar",
            "theme": "fusion"
          },
          "categories": [{
            "category": lstXL
          }],
          "dataset": [{
            "seriesName": srsName,
            "showValues": "1",
            "data": [{
              "value": "16000"
            },
            {
              "value": "20000"
            },
            {
              "value": "18000"
            },
            {
              "value": "19000"
            },
            {
              "value": "15000"
            },
            {
              "value": "21000"
            },
            {
              "value": "16000"
            },
            {
              "value": "20000"
            },
            {
              "value": "17000"
            },
            {
              "value": "25000"
            },
            {
              "value": "19000"
            },
            {
              "value": "23000"
            }]
          },
          {
            "seriesName": "Returns",
            "renderAs": "mscombi2d",
            "data": []
          },
          {
            "seriesName": "Net Consumption",
            "renderAs": "line",
            "data": [{
              "value": "15000"
            },
            {
              "value": "16000"
            },
            {
              "value": "17000"
            },
            {
              "value": "18000"
            },
            {
              "value": "19000"
            },
            {
              "value": "19000"
            },
            {
              "value": "19000"
            },
            {
              "value": "19000"
            },
            {
              "value": "20000"
            },
            {
              "value": "21000"
            },
            {
              "value": "22000"
            },
            {
              "value": "23000"
            }]
          }]
        }
      }).render();
    });
  }

  DoughNutChart(lstData: any[], chrtType: string, divId: string) {
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '430', height: '300', dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
            // "bgColor": "red",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "1",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "theme": "candy",
            "usePlotGradientColor": "0",            
            // "canvasbgColor": "red", // background of chart
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": lstData
        }
      }).render();
    });
  }

  onVldtTxtBoxs(tbVal: string, asCds: string): string {
    let opVal = '', aCs: number[] = this.spltAscCds(asCds);
    tbVal.split('').forEach(e => { if (aCs.filter(f => f == e?.charCodeAt(0)).length > 0) opVal += e; });
    return opVal;
  }

  spltAscCds(aV: string): number[] {
    let aVs: number[] = [];
    aV.split(',').forEach(e => {
      if (e?.includes('-')) {
        let x = +e?.split('-')[0], y = +e?.split('-')[1];
        for (let i = x; i <= y; i++)
          aVs.push(i);
      }
      else
        aVs.push(+e);
    });
    return aVs;
  }
  
}

export class RspnsAPI {
  data: any;
  message: string = '';
  returnCode: number = 0;
  status: string = '';
}